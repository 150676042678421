<!-- 组件模板内容 -->
<template>
	<div>
		<div class="headerWrap" :style="'background-image:url(' + item.url + ')'">
			<div class="search">
				<div>
					<div class="searchBox" @click="$router.push({ name: 'BeforeSearch', path: '/beforeSearch' })">
						<div>
							<input class="searchInput" type="text" :placeholder="$t('home.backgroundImages.search_like_goods' )" id="keyWord" />
							<div class="searchBtn">{{ $t('home.backgroundImages.search' )}}</div>
						</div>
					</div>
					<!-- <img v-if="item.isCar == 1"
						src="https://sjzcsaas.oss-cn-hangzhou.aliyuncs.com/sj/imageIndexAction/购物车11@2x.png"
						@click="$router.push('/shopCar')" /> -->
					<img v-if="item.iskefu == 1"
						src="https://sjzcsaas.oss-cn-hangzhou.aliyuncs.com/sj/imageIndexAction/客服@2x.png"
						@click="$router.push({ path: '/user', query: { showKefu: 1 } })" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "BackGroundImages",
	// 组件的props定义,用于子组件接收父组件传值
	props: {
		item: {
			type: Object,
		},
	},
	// 组件的data属性
	data() {
		return {};
	},
	// 组件的方法
	methods: {},
	// 在created钩子中执行的操作
	created() { },
};
</script>
<!-- 组件的样式 -->
<style scoped lang="less">
.headerWrap {
	box-sizing: border-box;
	width: 100%;
	padding: 1px;
	aspect-ratio: 16 / 9;
	max-width: 1080px;
	overflow-x: hidden;
	background: url(https://sjzcsaas.oss-cn-hangzhou.aliyuncs.com/sj/imageIndexAction/H5_03.png) no-repeat;
	background-size: 100% 100%;
	background-position: center;

	.search {
		z-index: 1;
		width: 100%;

		&>div {
			display: flex;
			align-items: center;

			&>img {
				width: 30px;
				height: 30px;
				margin: 0 10px;
			}
		}
	}

	.searchBox {
		width: 100%;
		padding: 14px 18px;
		&>div {
			position: relative;
			width: 100%;
			background: #fff;
			border-radius: 20px;
			input {
				border: none;
				width: 80%;
				height: 30px;
				padding-left: 15px;
				border-radius: 15px;
				color: #666;
				font-size: 14px;

				&:focus {
					outline: none;
				}
			}

			.searchBtn {
				line-height: 30px;
				font-size: 14px;
				position: absolute;
				right: 10px;
				top: 0;
				color: #666;
				font-weight: 400;
			}
		}
	}
}
</style>
