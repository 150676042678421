<!--  -->
<template>
	<div id="user">
		<div class="headerBox">
			<div class="headerTitle">
        {{ $t('user.person_center') }}
			</div>
			<div class="userMsgBox" style="margin-top: 20px;">
				<div style="display: flex;align-items: center;">
					<img style="width: 70px;height: 70px;border-radius: 50%;margin-right: 26px;"
						:src="userInfo.headImg != null ? userInfo.headImg : 'https://thirdwx.qlogo.cn/mmopen/vi_32/POgEwh4mIHO4nibH0KlMECNjjGxQUq24ZEaGT4poC6icRiccVGKSyXwibcPq4BWmiaIGuG1icwxaQX6grC9VemZoJ8rg/132'">
					<div>
						<div class="userName">{{ userInfo.name != null ? userInfo.name : '未设置名称' }}</div>
						<div class="editBtn">
							<img style="width: 16px;height: 14px;"
								src="https://yig-game.oss-cn-hangzhou.aliyuncs.com/wow_game/wow_img/buyRight/edit.png" />
							<div style="margin-left: 8px;" @click="userShow = true">{{$t('user.person_info')}}</div>
						</div>
					</div>
				</div>
				<div class="arrow-right"></div>
			</div>
			<div class="balanceBox" v-if="shopInfo.shopType != '2'">
				<div style="color: #FFB12E;">
					<div style="font-size: 14px;font-weight: 600;" v-if="userInfo.score">{{$t('user.my') + $store.state.unit }}: <span
							style="font-size: 18px;">{{ userInfo.score | decimal }}</span>{{
								$store.state.unit }}
					</div>
					<div style="font-size: 14px;font-weight: 600;" v-else>{{$t('user.my') + $store.state.unit }}: <span
							style="font-size: 18px;">0</span>{{
								$store.state.unit }}
					</div>
				</div>
				<div class="toExchangeBtn" @click="$router.push('/exchangePage')">{{$t('user.exchange_now')}}</div>
			</div>
		</div>
		<div class="orderTypeBox">
			<div class="orderTypeTitle">
				<div style="color: #333;font-size: 16px;font-weight: 600;">{{$t('user.my_order')}}</div>
				<!-- <div style="color: #A1A1A1;font-size:14px;">查看更多<van-icon name="arrow" /></div> -->
			</div>
			<div class="orderTypeList">
				<!-- <div class="orderTypeItem" @click="$router.push({ path: '/orderRecord',query:{active: 1} })">
					<img src="https://yig-game.oss-cn-hangzhou.aliyuncs.com/wow_game/wow_img/buyRight/待发货@2x.png"
						style="width: 35px;height: 35px;" />
					<div>待支付</div>
				</div> -->
				<div class="orderTypeItem" @click="$router.push({ path: '/orderRecord', query: { active: 0 } })">
					<img src="https://yig-game.oss-cn-hangzhou.aliyuncs.com/wow_game/wow_img/buyRight/待发货@2x.png"
						style="width: 35px;height: 35px;" />
					<!-- <div class="goodsNum">0</div> -->
					<div>{{$t('user.wait_send_goods')}}</div>
				</div>
				<div class="orderTypeItem" @click="$router.push({ path: '/orderRecord', query: { active: 1 } })">
					<img src="https://yig-game.oss-cn-hangzhou.aliyuncs.com/wow_game/wow_img/buyRight/待收货@2x.png"
						style="width: 35px;height: 35px;" />
					<!-- <div class="goodsNum">0</div> -->
					<div>{{$t('user.wait_receipt')}}</div>
				</div>
				<div class="orderTypeItem" @click="$router.push({ path: '/orderRecord', query: { active: 2 } })">
					<img src="https://yig-game.oss-cn-hangzhou.aliyuncs.com/wow_game/wow_img/buyRight/已完成@2x.png"
						style="width: 35px;height: 35px;" />
					<!-- <div class="goodsNum" wx:if="{{finished&&finished!=0}}">{{finished}}</div> -->
					<div>{{$t('user.completed')}}</div>
				</div>
			</div>
		</div>

		<div class="myServiceBox">
			<div class="myServiceTitle">{{$t('user.my_service')}}</div>
			<div class="myServiceContent">
				<div class="myServiceItem" @click="$router.push('/exchangeRecord')" >
					<img style="width: 35px;height: 35px;"
						src="https://yig-game.oss-cn-hangzhou.aliyuncs.com/wow_game/wow_img/buyRight/点数2.png" />
					<div class="myServiceName">{{$t('user.exchange_detail')}}</div>
				</div>
				<div class="myServiceItem" @click="$router.push('/addressList')">
					<img style="width: 35px;height: 35px;"
						src="https://yig-game.oss-cn-hangzhou.aliyuncs.com/wow_game/wow_img/buyRight/address@2x.png" />
					<div class="myServiceName">{{$t('user.address_manage')}}</div>
				</div>
				<div class="myServiceItem">
					<img style="width: 35px;height: 35px;"
						src="https://yig-game.oss-cn-hangzhou.aliyuncs.com/wow_game/wow_img/buyRight/客服.png" />
					<div class="myServiceName" @click="kefushow = !kefushow">{{$t('user.contact_kf')}}</div>
				</div>
				<div class="myServiceItem" v-if="shopInfo.afterSalesTel">
					<img style="width: 30px;height: 30px;margin-bottom:2px;"
						src="https://sjzcsaas.oss-cn-hangzhou.aliyuncs.com/sj/H5User/2024/shtk.png" />
					<div class="myServiceName" @click="shouHoushow = !shouHoushow">{{$t('user.contact_afs')}}</div>
				</div>

			</div>
		</div>

		<!-- <GoodsListWaterfall style="margin-top:20px;" :goods_data_left="goods_data_left"
			:goods_data_right="goods_data_right"></GoodsListWaterfall> -->
		<!-- <van-overlay :show="kefushow" @click="showKefu">
			<div class="wrapper">

			</div>
		</van-overlay> -->
		<van-action-sheet v-model="kefushow" :title="$t('user.kf_tel')">
			<div class="block" @click.stop>
				<div style="text-align:center;padding:20px;" @click="callPhone(shopInfo.customerTel)">{{ shopInfo.customerTel + $t('user.click_call')}}</div>
			</div>
		</van-action-sheet>

		<van-action-sheet v-model="shouHoushow" :title="$t('user.contact_afs')">
			<div class="block" @click.stop>
				<div style="text-align:center;padding:20px;" @click="callPhone(shopInfo.afterSalesTel)">{{ shopInfo.afterSalesTel + $t('user.click_call')}}</div>
			</div>
		</van-action-sheet>

		<van-action-sheet v-model="userShow" :title="$t('user.update_person_info')">
			<van-field name="uploader" :label="$t('user.head_img')">
				<template #input>
					<van-uploader ref="uploader" v-model="fileList" :after-read="afterRead" multiple :max-count="1" />
				</template>
			</van-field>
			<van-field v-model="userName" :label="$t('user.user_name')" :placeholder="$t('user.input_user_name')" />
			<div style="display:flex;width:94.6%;margin: 20px auto;">
				<div class="editUserInfoBtn" style="background: #999;margin-right:10px;" @click="removeUserInfo">{{$t('user.logout')}}</div>
				<div class="editUserInfoBtn" @click="editUserInfoApi">{{$t('user.save')}}</div>
			</div>

		</van-action-sheet>
	</div>
</template>
<style scoped>
.wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
}

.block {
	width: 100%;
	border-radius: 10px;
	padding: 10px;
	background-color: #fff;
}

div {
	box-sizing: border-box;
}

.headerBox {
	width: 100%;
	background-image: url('https://yig-game.oss-cn-hangzhou.aliyuncs.com/wow_game/wow_img/buyRight/userBg@2x.png');
	background-size: 100% 100%;
	background-position: center;
	padding: 12px;
	/* position: relative; */
}

.headerTitle {
	text-align: center;
	color: #333;
	font-size: 18px;
	height: 20px;
	font-weight: 600;
	margin-top: 10px;
}

.userMsgBox {
	width: 100%;
	padding: 0 15px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.userName {
	font-size: 16px;
	font-family: PingFang SC;
	color: #333333;
}

.editBtn {
	width: 90px;
	padding: 2px 0;
	background: #FFFFFF;
	border: 1px solid #B5B5B5;
	border-radius: 10px;
	font-size: 13px;
	font-family: PingFang SC;
	font-weight: 500;
	color: #666666;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 10px;
}

.balanceBox {
	width: 100%;
	padding: 20px;
	border-radius: 13px 13px 0px 0px;
	background-color: #302e2b;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 0 auto;
	margin-top: 20px;
}

.myServiceBox {
	width: 94.6%;
	/* height: 360px; */
	background: #FFFFFF;
	border-radius: 14px;
	margin: 0 auto;
	padding: 15px 10px;
	margin-top: 15px;
}

.myServiceTitle {
	font-size: 16px;
	font-family: PingFang SC;
	font-weight: 600;
	color: #333333;
	margin-left: 10px;
}

.myServiceContent {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
}

.myServiceItem {
	width: 33%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
	margin-top: 10px;
}

.toExchangeBtn {
	padding: 6px 15px;
	background: linear-gradient(90deg, #DCA433 0%, #F2CE85 100%);
	border-radius: 16px;
	text-align: center;
	font-size: 14px;
	color: #666;
}

.orderTypeBox {
	width: 94.6%;
	/* height: 140px; */
	padding: 10px 0px;
	background-color: #fff;
	border-radius: 14px;
	margin: 0 auto;
	margin-top: 5px;
	margin-bottom: 10px;
}

.orderTypeTitle {
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-weight: 600;
	color: #666;
	padding: 10px 20px;
	margin-bottom: 10px;
}

.orderTypeList {
	display: flex;
	align-items: center;
	justify-content: space-around;
}

.orderTypeItem {
	position: relative;
	display: flex;
	align-items: center;
	flex-direction: column;
	font-size: 14px;
	justify-content: space-between;
}

.goodsNum {
	position: absolute;
	background-color: #f00;
	width: 17px;
	height: 17px;
	font-size: 14px;
	color: #fff;
	text-align: center;
	line-height: 17px;
	border-radius: 50%;
	right: -6px;
	top: -6px;
}

.editUserInfoBtn {
	width: 94.6%;
	height: 40px;
	line-height: 40px;
	background: rgb(238, 10, 36);
	border-radius: 50px;
	text-align: center;
	color: #fff;
}

.myServiceName {
	font-size: 14px;
}
</style>
<style></style>
<script>
import GoodsListWaterfall from '@/components/GoodsListWaterfall';
import {getUserInfoApi, editUserInfoApi, getShopTypeApi} from '@/api/index'
import { Toast, Dialog } from 'vant';
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    GoodsListWaterfall,
  },
  data() {
    //这里存放数据
    return {
      goods_data_left: [],
      goods_data_right: [],
      kefushow: false,
      shouHoushow: false,
      userShow: false,
      userInfo: {},
      userName: '',
      avatar: '',
      fileList: [],
      shopInfo: {
      },
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    afterRead(file) {
      console.log(file);
      // 此时可以自行将文件上传至服务器
      this.avatar = this.base64ToImage(file.content);
    },
    // showKefu() {
    // 	this.kefushow = !this.kefushow
    // },
    async getUserInfo() {
      let params = {
        userToken: localStorage.getItem('userToken'),
        shopId: localStorage.getItem('shopId')
      }

      let { data } = await getUserInfoApi(params)
      this.userInfo = data
      if (data.headImg != null) {
        this.fileList.splice(0, 1)
        setTimeout(() => {
          this.fileList.push({ url: data.headImg })
          console.log(this.fileList);
        }, 500);
      }
      if (data.name != null) {
        this.userName = data.name
      }
    },
    //将base64转为图片
    base64ToImage(base64Str) {
      // base64字符串换为BLob对象
      let arr = base64Str.split(',');
      let mime = arr[0].match(/:(.*?);/)[1];
      let bstr = atob(arr[1]);
      let n = bstr.length;
      let u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      let blob = new Blob([u8arr], { type: mime });
      // 将BLob对象换为图片URL
      console.log(blob);
      let url = URL.createObjectURL(blob);
      console.log(url);
      return url;
    },

    //修改用户信息方法
    editUserInfoApi() {
      let that = this
      let params = new FormData()
      params.append('name', this.userName)
      params.append('file', this.$refs.uploader.value[0].file || '')
      console.log(this.$refs.uploader.value[0].file);
      editUserInfoApi(params).then(res => {
        that.userShow = false
        setTimeout(() => {
          that.getUserInfo()
          Toast.success(this.$t('user.update_user_info_success'))
        }, 1000);
      }).catch(res => {
        that.userShow = false
        setTimeout(() => {
          Toast.fail(res.msg)
        }, 1000);
      })
    },

    removeUserInfo() {
      Dialog.confirm({
        title: this.$t('user.tips'),
        message: this.$t('user.ask_confirm_logout'),
      }).then(() => {
        localStorage.removeItem('userToken')
        localStorage.removeItem('addressData')
        this.userInfo = {}
        this.$router.push('/')
      })
    },
    callPhone(phone) {
      window.location.href = 'tel://' + phone
    }

  },
  beforeCreate() { }, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    if (this.$route.query.showKefu && localStorage.getItem('userToken')) {
      this.kefushow = true
    }
    let item = localStorage.getItem('shopInfo');
    if (item){
      this.shopInfo = JSON.parse(localStorage.getItem('shopInfo'))
    }else{
      let item1 = localStorage.getItem('shopId');
      if (item1){
        getShopTypeApi({shopId: localStorage.getItem('shopId')}).then(({data})=>{
          this.shopInfo=data.shopInfo
          let s = JSON.stringify(this.shopInfo);
          localStorage.setItem('shopInfo', s);
        })
      }
    }

    this.getUserInfo()
  },
  beforeMount() { }, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {

  },
  beforeUpdate() { }, //生命周期 - 更新之前
  updated() { }, //生命周期 - 更新之后
  beforeDestroy() { }, //生命周期 - 销毁之前
  destroyed() { }, //生命周期 - 销毁完成
  activated() { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
