<template>
	<div class="pinpaiWrap">
		<div class="titleBox flex_sb">
			<div class="flex_no">
				<div style="font-weight: 600;font-size: 16px;color:#666;">{{$t('home.priceSearch.price_range')}}</div>
				<div class="flex_no qujianWrap">
					<input type="number" name="" id="" v-model="minSalePrice" />
					<div>~</div>
					<input type="number" name="" id="" v-model="maxSalePrice" />
					<div style="margin-left:10px;">{{$t('home.priceSearch.price_unit')}}</div>
				</div>
			</div>
			<div class="searchBtn" @click="searchBtn">{{$t('home.priceSearch.search')}}</div>
		</div>
		<div class="flex_no" style="margin-top: 10rpx;justify-content: space-between;flex-wrap: wrap;">
			<div class="qujianItem"  v-for="(i, index) in qujianData" :key="index" :style="`background:${item.bgColor};color:${item.color}`" @click="qujianBtn(i.minSalePrice,i.maxSalePrice)">{{ i.minSalePrice }}{{
          i.maxSalePrice == 0 ? '' : '-' }}{{
          i.maxSalePrice == 0 ? $t('home.priceSearch.and_above') : i.maxSalePrice + $t('home.priceSearch.price_unit') }}</div>
		</div>
	</div>
</template>
<style scoped>
.pinpaiWrap {
	width: 94.6%;
	background: #FFFFFF;
	border-radius: 18px;
	margin: 15px auto;
	margin-top: 0;
	padding: 20px 15px;
	box-sizing: border-box;
}

.qujianWrap {
	margin-left: 10px;
}

.qujianWrap input {
	width: 70px;
	height: 26px;
	text-align: center;
	border-radius: 8px;
	border: 1px solid #9E9E9E;
}

.qujianItem {
	width: 80px;
	height: 30px;
	background: #EDEDED;
	border-radius: 6px;
	font-family: Arial;
	//font-weight: 600;
	font-size: 12px;
	color: #666666;
	line-height: 30px;
	text-align: center;
}

.qujianItem {
	margin-top: 10px;
}

.searchBtn {
	width: 70px;
	height: 30px;
	border-radius: 8px;
	border: 1px solid #E85454;
	font-family: PingFang SC;
	font-weight: 400;
	font-size: 14px;
	color: #E85454;
	line-height: 30px;
	text-align: center;
}
</style>
<script>
export default {
	name: "PriceSearch",
	// 组件的props定义,用于子组件接收父组件传值
	props: {
		item: {
			type: Object,
		},
	},
	// 组件的data属性
	data() {
		return {
			minSalePrice: '',
			maxSalePrice: '',
			qujianData: [{
				minSalePrice: 0,
				maxSalePrice: 50
			}, {
				minSalePrice: 50,
				maxSalePrice: 100
			}, {
				minSalePrice: 100,
				maxSalePrice: 200
			}, {
				minSalePrice: 200,
				maxSalePrice: 300
			}, {
				minSalePrice: 300,
				maxSalePrice: 500
			}, {
				minSalePrice: 500,
				maxSalePrice: 800
			}, {
				minSalePrice: 800,
				maxSalePrice: 1000
			}, {
				minSalePrice: 1000,
				maxSalePrice: ""
			},]
		};
	},
	// 组件的方法
	methods: {
		searchBtn() {
      let query={
        minSalePrice: this.minSalePrice,
        maxSalePrice: this.maxSalePrice,
      }
      if (this.item.noCategory){
        query.noCategory = true
      }
			this.$router.push({
				path: '/search',
				query
			})
		},
		qujianBtn(a,b){
			this.minSalePrice = a
			this.maxSalePrice = b
			this.searchBtn()
		}
	},
	// 在created钩子中执行的操作
	mounted() {
    if (this.item.count){
      this.qujianData=this.qujianData.slice(0,this.item.count)
    }
  },
};
</script>
