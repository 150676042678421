<template>
  <div class="prefectureCardImage flex_sb" :style="`background-image:url(${item.bannerUrl});height:${height}px;padding-top:1px;`" @click="$router.push({ path: '/topicDetail', query: { topicId: item.topicId,title:item.title ,load:true} })">
    <div></div>
    <div class="more_btn" @click="$router.push({ path: '/topicDetail', query: { topicId: item.topicId,title:item.title,load:true } })">{{$t('home.prefectureCard.loadImg.view_more')}} </div>
  </div>
</template>
<script>
import {getImageHeightRatio, getImageRatio} from "@/utils/imgUtils";

export default {
  data() {
    return {
      imageRatios: "",
      height:""
    }
  },
  props:{
    item: {
      type: Object,
    },
    index: {
      type: Number,
    }
  },
  methods:{
    async loadImg(url){
      let element = document.getElementsByClassName('prefectureCardImage');
      getImageHeightRatio(url).then(r=>{
          this.imageRatios=r;
          this.height=element[0].clientWidth*eval(r);
        })
    },
  },
  mounted() {
    this.loadImg(this.item.bannerUrl)
  },

}
</script>
<style>
.prefectureCardImage {
  width: 100%;
  border-radius: 14px 14px 0px 0px;
  background-position: center;
  background-size: 100% 100%;
  box-sizing: border-box;
  padding: 0 15px;
}

.more_btn {
  width: 70px;
  height: 22px;
  background: #FFFFFF;
  border-radius: 20px;
  font-size: 12px;
  text-align: center;
  line-height: 22px;
}
</style>
