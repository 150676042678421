<template>
	<div class="choseAddress">
		<van-radio-group v-model="radio" @change="selectAddress">
			<van-radio :name="index" v-for="(item, index) in addressList" :key="index" checked-color="#ee0a24"
				style="margin-bottom:15px;">
				<div class="addressItem">
					<div class="flex_no" style="height:18px;line-height:18px:">
						<!-- <div>{{ item.userName }}</div>
						<div style="margin-left:5px;">{{ item.tel }}</div> -->
						<div class="oneLineBox">{{ item.address }}</div>
					</div>
					<div class="addressDetail" style="margin-top:5px;">{{ item.prowz }} {{ item.citywz }} {{ item.areawz }}
						{{ item.streewz }} </div>
				</div>
			</van-radio>
		</van-radio-group>
	</div>
</template>
<style scoped>
.oneLineBox {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.flex_no {
	display: flex;
	align-items: center;
}

.choseAddress {
	padding: 0 10px;
	min-height: 20vh;
	max-height: 40vh;
}

.addressItem {
	max-width: 320px !important;
	margin-left: 10px;
	font-size: 14px;
}

.addressDetail {
	font-size: 12px;
	color: #333;
}
</style>
<script>
export default {
	props: {
		addressList: {
			type: Array,
			default: []
		}
	},
	methods: {
		selectAddress(index) {
			this.$emit('selectAddress', index)
		}
	},
	data() {
		return {
			radio: 0
		}
	},
	created() {
		let index = 0
		if (localStorage.getItem('selectAddressData')) {
			index = this.addressList.findIndex(item => {
				return item.id == JSON.parse(localStorage.getItem('selectAddressData')).id
			})
		} else {
			index = this.addressList.findIndex(item => {
				return item.id == JSON.parse(localStorage.getItem('addressData')).id
			})
		}
		index > -1 ? this.radio = index : this.radio = 0
	}
}
</script>
