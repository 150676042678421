<template>
  <div id="goodsZone">
    <div style="position: relative">
      <div style="display: flex;position: absolute;top: 12px;left: 16px;align-items: center">
        <div style="font-size: 18px;font-weight: bold">{{item.title}}</div>
        <div style="margin: 0 7px">|</div>
        <div style="font-size: 12px">{{item.subTitle}}</div>
      </div>
      <div class="moreBtn" @click="jumpPage(item)">
        <img src="https://sjzcsaas.oss-cn-hangzhou.aliyuncs.com/sj/applet/kaboshi/zone/btn.png" width="90px" alt="">
      </div>
      <div class="goodsList">
        <div v-for="(item,index) in goodsList" :key="index"  style="background: #fff;border-radius: 10px 10px 0 0;padding-bottom: 4px;width: 110px" @click="goDetail(item)">
          <div>
            <img :src="item.picUrl | jdUrl" width="110px">
          </div>
          <div style="margin-top: 2px;overflow: hidden;text-overflow: ellipsis;white-space:nowrap;font-size: 12px;padding: 0 2px">
            {{item.title}}
          </div>
          <div style="margin-top: 2px;font-size: 14px;padding: 0 2px;font-weight: bold">
            {{item.shopSalePrice | decimal}} <span>{{item.shopUnit}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {getActiveGoodsApi} from "@/api";

export default {
  name: "GoodsZone",
  props: {
    item: {
      type: Object,
    }
  },
  data() {
    return{
      goodsList:[],
      params:{
        wxShopId:localStorage.getItem("shopId"),
        distributorId: localStorage.getItem('distributorId'),
        isJd:1,
        pageIndex:1,
        pageSize:10,
        title:'',
        topicId:this.item.topicId,
      }
    }
  },
  methods:{
    jumpPage(){
      localStorage.setItem("reload",true)
      this.$router.push({ path: '/search', query: { isJd:"1" ,topicId:this.item.topicId,title:this.item.title,pageIndex:1} })
    },
    goDetail(item){
      this.$router.push({
        path: '/GoodsDetail',
        query: { id: item.goodsId, isJd: item.isJd, ruleType: item.relationType, relationId: item.relationId,shopId:localStorage.getItem("shopId") }
      })
    },
    async getGoodsList(){
      let {data}= await getActiveGoodsApi(this.params)
      this.goodsList=data.list.splice(0,3);
    }
  },
  created() {
    this.getGoodsList()
  },
}
</script>
<style>
.goodsList{
  position: absolute;
  top:48px ;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}
#goodsZone{
  width: 94.6%;
  margin: 0 auto;
  height: 230px;
  background-image: linear-gradient(0deg, #fff 0%, rgb(255,217,179) 100%);
  border-radius:10px;
}
.moreBtn{
  position: absolute;
  top: 0;
  right: 0;
}
</style>
