<template>
	<div class="headerBox" :style="item.backgroundUrl?`background-image:url('${item.backgroundUrl}');`:`background-color:${item.backgroundColor}`">
		<img  :src="item.logoUrl" height="50px" v-if="item.logoUrl"/>
		<div class="search_wrap" @click="Jumppage">
			<div class="input_wrap">
				<van-icon name="search" size="18" />
				<input type="text" :placeholder="$t('home.bankHeader.input_keyword_search')">
			</div>
			<div class="search_btn">{{$t('home.bankHeader.search')}}</div>
		</div>
		<img class="banner" :src="item.bannerUrl" alt="" @click="jumpPages">
	</div>
</template>
<style scoped>
.headerBox {
	padding: 15px 10px;
}

.logo {
	width: 112px;
	height: 31px;
}

.search_wrap {
	width: 100%;
	aspect-ratio: 10/1;
	background: #fff;
	display: flex;
	align-items: center;
	padding: 4px 10px;
	padding-right: 0;
	border-radius: 20px;
	box-sizing: border-box;
	margin: 10px auto;
}

.input_wrap {
	display: flex;
	align-items: center;
	width: 80%;
	height: 100%;
}

.search_wrap input {
	width: 100%;
	height: 100%;
	border: 0;
	margin-left: 8px;
	color: #666;
	font-size: 14px;
}

.search_btn {
	width: 20%;
	height: 28px;
	line-height: 28px;
	text-align: center;
	font-size: 14px;
}

.banner {
	aspect-ratio: 712/230;
	width: 100%;
}
</style>
<script>
export default {
	name: "BankHeader",
	// 组件的props定义,用于子组件接收父组件传值
	props: {

		item: {
			type: Object,
		},
	},
	// 组件的data属性
	data() {
		return {
      shopInfo: {}
		};
	},
	// 组件的方法
	methods: {
    jumpPages(){
      location.href = this.item.jumpPgae
    },
		Jumppage() {
			this.$router.push('/beforeSearch')
		},
	},
	// 在created钩子中执行的操作
	created() {
    this.shopInfo = JSON.parse(localStorage.getItem('shopInfo'))
  },
};
</script>
