export const app={
    "home":"首頁",
    "cart":"購物車",
    "me":"我的"
}

export const home = {
    backgroundImages: {
        search: "搜索",
        search_like_goods: "搜想要的商品"
    },
    bandGoodsArea: {
        buy_now: "立即購買",
        view_more: "查看更多",
    },
    bankHeader: {
        search: "搜索",
        input_keyword_search: "請輸入關鍵字搜索"
    },
    commodityArea: {
        product_recommendations: "商品推薦",
        no_more: "沒有更多了"
    },
    headerSearch: {
        search_like_goods: "搜想要的商品",
        search: "搜索"
    },
    jinGang: {
        more: "更多"
    },
    porcelainTileArea: {
        priority_zone: "優選專區",
        select_good_quality_goods: "精選優質好物",
        stay_tuned: "敬請期待",
        proprietary_area: "自營專區",
        genuine_guarantee_jd_logistics: "正品保障 京東物流"
    },
    prefectureCard: {
        loadImg: {
            view_more: "查看更多 >"
        }
    },
    priceSearch:{
        price_range:"價格區間",
        price_unit:"元",
        search: "搜索",
        and_above:"及以上"
    }
}

export const address = {
    addressList:{
        address_list:"地址列表",
        default:"默認",
        no_address_info:"暫無地址信息",
        add_address:"新增地址",
        create_address:"創建地址",
        tips:"提示",
        confirm_delete:"確定刪除該地址嗎？",
        delete_success:"刪除成功",
    },
    createAddress:{
        receiver_name:"收貨人姓名",
        input_receiver_name:"請輸入收貨人姓名",
        receiver_contact_info:"聯繫方式",
        input_receiver_contact_info:"請輸入收貨人手機號",
        province_city_area:"省市區",
        choose_province_city_area:"請選擇省市區",
        choose_your_region:"請選擇所在地區",
        address_detail:"詳細地址",
        input_address_detail:"請輸入詳細地址",
        set_it_as_default:"設為默認地址",
        confirm:"確認",
        create_address_success:"地址添加成功",
    },
    editAddress:{
        edit_address:"編輯地址",
        receiver_name:"收貨人姓名",
        input_receiver_name:"請輸入收貨人姓名",
        receiver_contact_info:"聯繫方式",
        input_receiver_contact_info:"請輸入收貨人手機號",
        province_city_area:"省市區",
        choose_province_city_area:"請選擇省市區",
        choose_your_region:"請選擇所在地區",
        address_detail:"詳細地址",
        input_address_detail:"請輸入詳細地址",
        set_it_as_default:"設為默認地址",
        confirm:"確認",
        edit_address_success:"地址編輯成功",
    },
    addressItem:{
        choose_address:"請選擇地址",
        default: "默認",
        deliver_to: "送至",
        deliver_to2:"配送至",
        create_address:"創建地址",
        add_address: "新增地址",
        edit_address:"編輯地址",
    }
}

export const exchange = {
    exchange_balance:"兑換餘額",
    input_card_secret:"請輸入卡密",
    input_tel:"請輸入手機號",
    confirm:"確定",
    tips_1:"友情提示：",
    tips_2:"1、請填寫會員卡上卡密。",
    tips_3:"2、請確認您的卡密，確認後不可更改。",
    tips_4:"3、兑換成功後，在【我的】中查看餘額明細。",
    tips_5:"4、餘額兑換後五年內有效。",

}
export const record={
    exchangeRecord:{
        exchange_detail:"兑換明細",
        user_recharge:"用户充值",
        backend:"後台",
        adjust:"調整",
        shopping_use:"購物使用",
        refund_increase:"退單增加",
        no_exchange_detail:"暫無兑換明細",
    },
    orderRecord:{
        my_order:"我的訂單",
        wait_send_goods:"待發貨",
        refund:"退款",
        wait_receipt:"待收貨",
        completed:"已完成",
        confirm_receipt:"確認收貨",
        view_logistics_info:"查看物流信息",
        apply_afs:"申請售後",
        view_process:"查看進度",
        reapply_afs:"重新申請售後",
        no_relevant_order:"暫無相關訂單",
        apply_refund_success:"申請退款成功",
        ask_confirm_receipt:"確認收到貨了嗎？",
        whether_goods_received:"是否已經收到貨？",
        confirm_receipt_success:"確認收貨成功",
        confirm_receipt_fail:"確認收貨失敗",
        receipt_completed:"收貨完成",
        receipt_fail_wait_try_again:"收貨失敗，請稍後重試",
    }
}
export const beforeSearch={
    search:"搜索",
    back:"返回",
    input_goods_name:"請輸入商品名稱",
    search_history:"搜索歷史",
    no_search_history:"暫無搜索歷史",
    tips:"提示",
    ask_confirm_delete_history:"是否確認清空搜索歷史？"
}

export  const homeView={
    shop_no_exist_or_expired:"商城不存在或已過期",
    use_jd_redefine_benefit:"\n用京東重新定義福利",

}

export const goodsDetail={
    goods_detail:"商品詳情",
    market_price:"市場價:",
    market_price_unit:"元",
    click_jd_compare:"點擊前往京東比價",
    quantity:"數量",
    logistics:"物流",
    specification_param:"規格參數",
    add_cart:"加入購物車",
    buy_now:"立即購買",
    use_jd_redefine_benefit:"\n用京東重新定義福利",
    add_cart_success:"添加購物車成功",
}

export const login = {
    login_registration: "登錄註冊",
    tel: "手機號",
    please_input_tel: "請輸入手機號",
    sms_verification_code: "短信驗證碼",
    input_sms_verification_code: "請輸入短信驗證碼",
    send_sms_verification_code: "發送短信驗證碼",
    login: "登錄",
    register: "註冊",
    code_invalid_obtain_again: "驗證碼已失效，請重新獲取",
    input_correct_tel: "請輸入正確的手機號",
    forget_password: "忘記密碼",
}

export  const logistics={
    logistics_info:"物流信息",
    no_logistics_info:"暫無物流信息",
    copy_success:"複製成功",
    logistics_company:"物流公司",
    logistics_number:"物流單號",
    logistics_status:"物流狀態",
}

export const orderDetail={
    write_order:"填寫訂單",
    total_txt:"共",
    unit:"件",
    goods_money:"商品金額",
    freight:"運費",
    total_money_txt:"合計：",
    submit_order:"提交訂單",
    goods_list:"商品清單",
    payment:"去支付",
    payment_success:"支付成功",
    payment_fail_and_try_again_or_kefu:"支付失敗，請聯繫客服或稍後重試",
    add_receipt_address:"請先添加收貨地址",
}
export const paySuccess = {
    pay_success:"支付成功",
    back_home:"返回首頁",
    view_detail:"查看詳情",
}

export const refund = {
    apply_refund:"申請退款",
    refund_reason:"退款原因",
    input_refund_reason:"請輸入退款原因",
    total_txt:"共",
    unit:"件",
    refund_info:"退款信息",
    choose_refund_reason:"請選擇退款原因",
    refund_amount:"退款金額",
    refund_amount_Tips:"不可修改,退款金額將原路返回至您的賬户",
    money_unit:"元",
    afs_type:"售後類型",
    choose_afs_type:"請選擇",
    choose_afs_type_2:"請選擇售後類型（必填）：",
    choose_afs_type_3:"請選擇售後類型",
    only_support_return_goods_and_money:"當前僅支持退貨退款",
    add_descriptions_credential:"補充描述和憑證",
    add_descriptions_credential_tips:"補充描述，有助於商家更好的處理售後問題~",

    return_method:"退貨方式",
    choose_return_method:"請選擇",
    choose_return_method_2:"請選擇退貨方式（必填）：",
    return_goods:"退貨",
    return_goods_and_money:"退貨退款",
    return_goods_and_money_tips:"僅支持退貨退款",
    my_address:"我的地址",
    home_pick_up_time:"上門時間",
    submit_refund:"提交",

    cancel_order:"取消訂單",
    warm_reminder:"溫馨提示",
    warm_reminder_tip_1:"1.消費者因個人原因申請退貨，包郵商品需承擔返回運費， 非包郵商品需承擔送返兩程運費",
    warm_reminder_tip_2:"2.訂單一旦取消，支付優惠也一併取消",
    warm_reminder_tip_3:"3.退款金額將原路返回至您的支付賬户",
    cancel_order_reason:"請選擇取消訂單的原因（必填）：",

    confirm:"確定",
    choose_home_pick_up_time:"選擇時間",
    choose_home_pick_up_time_2:"請選擇取件時間",
    deliver_to:"配送至",
    add_address:"新增地址",
    create_address:"創建地址",
    goods_no_stock:"商品庫存不足",
    send_goods_question:"商品發貨問題",
    dont_want:"不想要了",
    mis_multiple_selection:"商品錯選/多選",
    seven_days_no_reason:"七天無理由退貨",
    price_reduction:"商品降價",
    return_goods_refund:"退貨退款",

    door2door_pickup:"上門取件",
    customer_send:"客户送貨",
    customer_delivery:"客户發貨",
    support_seven_day_no_reason:"支持7天無理由退貨",
    only_refund:"僅退款",
    submit_success:"提交成功",
    input_question_remark:"請輸入問題描述",
    upload_least_one_picture:"請至少上傳一張問題圖片",
    choose_pickup_address:"請選擇取件地址",
}

export const refunding = {
    view_progress:"查看進度",
    refund_info:"退款信息",
    refund_amount: "退款金額",
    refund_reason:"退款原因",
    apply_time:"申請時間",
    order_no:"訂單編碼",
    return_method:"退貨方式",
    my_address:"我的地址",
    door2door_pickup_time:"上門時間",
    delivery_address:"收貨地址",
    copy:"複製",
    upload_logistics_no:"上傳單號",
    logistics_company:"物流公司：",
    logistics_number:"物流單號：",
    send_ok_go_fill_it:"我已寄出，去填寫物流單號",
    fill_logistics_number:"填寫單號",
    refund_to_account:"退款到賬",
    money_unit:"元",
    rejection_grounds:"駁回理由",
    confirm_completion:"確認完成",
    contact_customer_service:"聯繫客服介入",
    input_logistics_number:"填寫物流單號",
    please_input_logistics_number:"請填寫物流單號",
    freight:"運費",
    input_freight:"請輸入運費金額",
    input_logistics_company:"請輸入物流公司：",
    confirm:"確認",
    contact_afs:"聯繫售後",

    wait_merchant_review:"待商家審核",
    wait_goods_return:"待寄回商品",
    pending_merchant_process:"待商家處理",
    completed:"已完成",
    cancelled:"已取消",
    reject:"駁回",
    submit_logistics_info_ok:"提交物流信息成功",
    submit_success:"提交成功",
    copy_success:"複製成功",
    no_support_copy:"該瀏覽器不支持自動複製"


}

export  const  search = {
    proprietary_area : "自營專區",
    priority_zone:"優選專區",
    search:"搜索",
    back:"返回",
    no_more:"沒有更多了",
    input_goods_name:"請輸入商品名稱",
    all:"全部",
    loading:"加載中..."

}

export const shopCar = {

    jd:"JD京東",
    sj:"官方自營",
    delete:"刪除",
    empty:"購物車空空如也~",
    select_all:"全選",
    next_step:"下一步",
    tips:"提示",
    ask_confirm_delete:"是否確認刪除該商品？",
    delete_success:"刪除成功",
    no_buy_goods:"您還沒有選購商品",
    no_receipt_address:"您還沒有收穫地址",
}
export const topicDetail = {
    no_more:"沒有更多了",
}
export const user = {
    person_center:"個人中心",
    person_info:"個人信息",
    my:"我的",
    exchange_now:"立即兑換",
    my_order:"我的訂單",
    wait_send_goods:"待發貨",
    wait_receipt:"待收貨",
    completed:"已完成",
    my_service:"我的服務",
    exchange_detail:"兑換明細",
    address_manage:"地址管理",
    contact_kf:"聯繫客服",
    contact_afs:"聯繫售後",
    kf_tel:"客服聯繫方式",
    update_person_info:"修改個人信息",
    user_name:"用户名",
    input_user_name:"請輸入用户名",
    logout:"退出登錄",
    head_img:"頭像",
    save:"保存",
    click_call:"(點擊撥號)",
    update_user_info_success:"修改用户信息成功",
    tips:"提示",
    ask_confirm_logout:"是否確認退出登錄？",
}

export const goodsListWaterFall = {
    no_goods_exist:"很抱歉，商品不存在",
    change_keyword_or_try_again:"您可更換關鍵詞搜索或稍後再試",
    loading:"加載中...",
    money_unit:"元"
}

export const searchComponent = {
    search:"搜索",
    input_keyword_search:"請輸入關鍵字搜索",
    no_goods_exist:"很抱歉，商品不存在",
    change_keyword_or_try_again:"您可更換關鍵詞搜索或稍後再試",
    loading:"加載中..."
}
export const request = {
    loading:"加載中...",
    no_login:"用户未登錄,是否前往登錄",
    tip:"提示"
}
