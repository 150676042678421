<!--  -->
<template>
  <div id='app'>
    <van-nav-bar placeholder fixed :title="$route.query.title" left-arrow @click-left="$router.back()"/>
    <van-list
        v-model="loading"
        :finished="finished"
        :finished-text="$t('topicDetail.no_more')"
        @load="load"
    >
      <GoodsListWaterfall :goods_data_right="goods_data_right" :goods_data_left="goods_data_left"></GoodsListWaterfall>
    </van-list>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import GoodsListWaterfall from '@/components/GoodsListWaterfall';
import {getActiveGoodsApi} from '@/api/index';
import {Toast} from 'vant';
import {_debounce} from "@/main";

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    GoodsListWaterfall
  },
  data() {
    //这里存放数据
    return {
      list: [],
      loading: false,
      finished: false,
      pageIndex: 1,
      goods_data: [],
      goods_data_left: [],
      goods_data_right: [],
      topicId: 0
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {
    '$route'(to, from) {
      // 判断是否是从商品列表进入商品详情页面
      if (from.name==="home"){
        location.reload();
      }
    }
  },
  //方法集合
  methods: {
    load(){
      setTimeout(() => {
        // 没有数据后，不触发请求
        this.pageIndex += 1
        this.getGoodsApi()
        // 加载状态结束
        this.loading = false;
      }, 1000);
    },
    getGoodsApi: _debounce(async function () { //获取商品列表
      let params = {
        wxShopId: localStorage.getItem('shopId'),
        distributorId: localStorage.getItem('distributorId'),
        topicId: this.topicId,
        isJd: '',
        pageIndex: this.pageIndex,
        pageSize: 10,
        title: ''
        // categoryId: this.categoryId,
        // categoryLevel:this.categoryLevel
      }
      if (params.pageIndex == 1) {
        window.addEventListener('scroll', this.listenBottomOut)
      }
      let {data} = await getActiveGoodsApi(params);
      Toast.clear()
      this.goods_data = data.list
      this.pageIndex = data.pageIndex
      for (let i = 0; i < this.goods_data.length; i++) {
        if (this.goods_data_left.length <= this.goods_data_right.length) {
          this.goods_data_left.push(this.goods_data[i])
        } else {
          this.goods_data_right.push(this.goods_data[i])
        }
      }
    }, 500),
    listenBottomOut() {
      // const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      // const clientHeight = document.documentElement.clientHeight
      // const scrollHeight = document.documentElement.scrollHeight
      // if (scrollTop + clientHeight >= scrollHeight - 1) {
      //   Toast.loading({
      //     message: '加载中...',
      //     forbidClick: true,
      //   });
      //   // 没有数据后，不触发请求
      //   this.pageIndex += 1
      //   this.getGoodsApi()
      // }
    },
  },
  beforeCreate() {
  }, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.topicId = this.$route.query.topicId
    this.pageIndex = 1
    this.getGoodsApi()
  },
  beforeMount() {
  }, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {

  },
  beforeUpdate() {
  }, //生命周期 - 更新之前
  updated() {
  }, //生命周期 - 更新之后
  beforeDestroy() {
  }, //生命周期 - 销毁之前
  destroyed() {
    window.removeEventListener('scroll', this.listenBottomOut, false)
  }, //生命周期 - 销毁完成
  activated() {
    if (this.$route.query.load) {
      this.topicId = this.$route.query.topicId
      this.pageIndex = 1
      this.goods_data = []
      this.goods_data_left = []
      this.goods_data_right = []
      this.getGoodsApi()
    }
    if (this.topicId != this.$route.query.topicId) {
      this.topicId = this.$route.query.topicId
      this.pageIndex = 1
      this.goods_data = []
      this.goods_data_left = []
      this.goods_data_right = []
      this.getGoodsApi()
    }
  }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style></style>
