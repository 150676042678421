<!-- 购物车页面 -->
<template>
	<div id='shopcar'>
		<div class="headerBox">
			<AddressItem></AddressItem>
			<!-- <img class="line" src="https://yig-game.oss-cn-hangzhou.aliyuncs.com/wow_game/wow_img/starCloud/new/line.png"
				alt=""> -->
		</div>
		<!--购物车列表-->
		<div v-if="goodsData && goodsData.length > 0">
			<div class="goodsItem" v-for="(item, index) in goodsData" :key="index">
				<div class="checkBox">
					<van-checkbox v-model="item.checked" checked-color="#ee0a24"
						@change="changeState(item.checked, index)">{{ item.type == 1 ? $t('shopCar.jd') :$t('shopCar.sj')
						}}</van-checkbox>
					<div class="removeBtn" @click="removeGoods(index, item.id)">{{ $t('shopCar.delete') }}</div>
				</div>
				<van-card :num="item.num" :price="(item.disPrice * 1).toFixed(priceLength) + $store.state.unit" :title="item.title"
					:thumb="item.picUrl">
					<template #footer>
						<van-stepper v-model="item.num" integer @change="changeNum(item.num, index, item.id)" />
					</template>
				</van-card>
			</div>
		</div>
		<!--当商品数据为空时-->
		<div v-else class="toast_title">
			<img src="https://img.yzcdn.cn/vant/custom-empty-image.png" alt="">
			<div>{{ $t('shopCar.empty') }}~</div>
			<div style="margin-top:6px;"></div>
		</div>
		<div style="height:120px;"></div>
		<!--提交订单栏-->
		<van-submit-bar :currency="''" :suffix-label="$store.state.unit" :decimal-length="priceLength" :price="(allPrice * 100)" :button-text="$t('shopCar.next_step')"
			@submit="submitBtn">
			<van-checkbox v-model="checkedAll" checked-color="#ee0a24" @click="changeAll">{{$t('shopCar.select_all')}}</van-checkbox>
			<!-- <template #tip>
				商品金额:{{ allPrice }}, <span>运费:0</span>
			</template> -->
		</van-submit-bar>
	</div>
</template>
<style scoped>
.headerBox {
	background: #fff;
	position: relative;
}

.headerTitle {
	text-align: center;
	color: #333;
	font-size: 18px;
	height: 20px;
	font-weight: 600;
	margin-top: 10px;
}

.flex_no {
	display: flex;
	align-items: center;
}

.line {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
}

.goodsItem {
	background: #fff;
	width: 94.6%;
	margin: 0 auto;
	margin-top: 10px;
	border-radius: 10px;
}

.van-card {
	background: #fff !important;
	border-radius: 0 0 10px 10px;
}

.van-card__header {
	align-items: center;
}

.van-card__title {
	font-size: 14px;
}

.van-card__content {
	min-height: 70px !important;
}

.van-card__price-currency {
	display: none;
}

.van-card__price {
	font-size: 14px !important;
	color: #da4228 !important;
}

.van-card__num {
	font-size: 16px;
}

.van-card:not(:first-child) {
	margin-top: 0px !important;
}

.van-submit-bar {
	bottom: 50px !important;
}

.checkBox {
	padding: 10px;
	padding-bottom: 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.van-checkbox {
	font-size: 15px;
}

.removeBtn {
	font-size: 13px;
	color: #666;
	text-decoration: underline;
}

.toast_title {
	text-align: center;
	font-size: 14px;
	color: #999;
}

.toast_title img {
	width: 30%;
	padding: 1px;
	aspect-ratio: 1/1;
	margin: 20px 0;
}
</style>
<script>
import AddressItem from '@/components/Address/AddressItem';
import { Dialog, Toast } from 'vant';
import {getShopCarDataApi, upDateGoodsNumApi, removeGoodsApi, checkCartCanBuy, getShopTypeApi} from '@/api/index'
import {mapState} from "vuex";
export default {
	components: {
		AddressItem,
	},
	data() {
		//这里存放数据
		return {
			allPrice: 0, //总价
			checkedAll: false,	//全选按钮状态
			choseGoodsArr: [], //选中的商品数据
			goodsData: [],
		};
	},
	computed: {
    ...mapState(['priceLength'])
  },
	//监控data中的数据变化
	watch: {},
	//方法集合
	methods: {
		async getShopCarData() {
			let params = {
				userToken: localStorage.getItem('userToken'),
				shopId: localStorage.getItem('shopId')
			}
			getShopCarDataApi(params).then(res => {
				//1jd 2自营
				if (res.code == 200) {
					console.log(res.data);
					res.data.forEach(item => {
						item.checked = false
					})
					this.goodsData = res.data
				}
			}).catch(res => {
				console.log(res);
			})
		},

		//修改商品数量
		async changeNum(num, index, id) {
			console.log(num, index);
			this.goodsData[index].num = num //修改页面商品数量

			//调接口修改商品数量 ↓
			await upDateGoodsNumApi({ num, id })
			//计算价格
			this.computeAllPrice()
		},

		//删除购物车商品
		removeGoods(index, id) {
			console.log(index, id);
			//调接口删除
			Dialog.confirm({
				title: this.$t('shopCar.tips'),
				message: this.$t('shopCar.ask_confirm_delete'),
			}).then(() => {
				removeGoodsApi({ id: id }).then(res => {
					if (res.code == 200) {
						Toast.success(this.$t('shopCar.delete_success'))
						setTimeout(() => {
							this.goodsData.splice(index, 1) //页面中删除该商品
							this.getShopCarData()
						}, 1000);
					}
				}).catch(res => {
					Toast.fail(res.msg)
				})
			})
		},

		//全选
		changeAll() {
			if (this.checkedAll) { //判断状态 商品状态和全选按钮状态一致
				this.goodsData.forEach(item => {
					item.checked = true
				})
			} else {
				this.goodsData.forEach(item => {
					item.checked = false
				})
			}
		},

		//单选
		changeState(state, index) { // state：状态  index:下标
			this.goodsData[index].checked = state
			if (state) {
				this.choseGoodsArr.push(this.goodsData[index])
			} else {
				let spliceIndex = this.choseGoodsArr.findIndex(item => {
					return this.goodsData[index].id === item.id
				})
				this.choseGoodsArr.splice(spliceIndex, 1)
			}
			this.initData()
		},

		//反选
		initData() {
			if (this.choseGoodsArr.length == this.goodsData.length) {
				this.checkedAll = true
			} else {
				this.checkedAll = false
			}
			this.computeAllPrice()
		},

		//计算商品总价
		computeAllPrice() {
			let allPrice = 0
			this.choseGoodsArr.forEach(item => {
				allPrice += item.disPrice * item.num
			})
			this.allPrice = allPrice.toFixed(this.priceLength)
		},

		//提交订单
		async submitBtn() {
      let shopId = localStorage.getItem("shopId");
      if (!shopId){
        Toast.fail(this.$t("shopCar.no_shop"))
        return;
      }else{
        let {data:res} = await getShopTypeApi({shopId: localStorage.getItem('shopId')})
        if (res) {
          if (+new Date() > +new Date(res.shopInfo.endTime)) {
            Toast.fail(this.$t("shopCar.shop_no_exist_or_expired"))
            return false
          }
          if (res.shopInfo.status===2){
            Toast.fail(this.$t("shopCar.shop_stop"))
            return false
          }
        }
      }
			if (this.choseGoodsArr.length == 0) {
				Toast(this.$t("shopCar.no_buy_goods"))
				return
			}
			if(!localStorage.getItem('selectAddressData')&&!localStorage.getItem('addressData')){
				Toast(this.$t("shopCar.no_receipt_address"))
				return
			}
      let param = {
        checkListId: this.choseGoodsArr.map(item => item.id),
        shopId: localStorage.getItem('shopId'),
        bindDistributorId: localStorage.getItem('distributorId')
      }
      let {data} =await checkCartCanBuy(param)
      if(!data.canBuy){
        Toast(data.errorMsg)
        return
      }

			this.$router.push({
				path: '/orderDetail',
				query: { path: 'shopcar', addressId: localStorage.getItem('selectAddressData') ? JSON.parse(localStorage.getItem('selectAddressData')).id : JSON.parse(localStorage.getItem('addressData')).id }
			})
			localStorage.setItem('orderData', JSON.stringify(this.choseGoodsArr))
		}
	},
	beforeCreate() { }, //生命周期 - 创建之前
	//生命周期 - 创建完成（可以访问当前this实例）
	created() {
		this.getShopCarData()
	},
	beforeMount() { }, //生命周期 - 挂载之前
	//生命周期 - 挂载完成（可以访问DOM元素）
	mounted() {

	},
	beforeUpdate() { }, //生命周期 - 更新之前
	updated() { }, //生命周期 - 更新之后
	beforeDestroy() { }, //生命周期 - 销毁之前
	destroyed() { }, //生命周期 - 销毁完成
	activated() { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style></style>
