<!-- 组件模板内容 -->
<template>
  <div>
    <BackGroundImages
        v-if="item.name == 'header-backgroundAndSearch'" :item="item"></BackGroundImages>
    <JinGang v-if="item.name == 'jinGang'" :item="item" style="margin:10px 0;"></JinGang>
    <BannerArea v-if="item.name == 'active-banner'" :item="item"></BannerArea>
    <GoodsZone v-if="item.name == 'goods-zone'" :item="item"></GoodsZone>
    <PorcelainTileArea v-if="item.name == 'porcelainTileArea'" :item="item"></PorcelainTileArea>
    <CommodityArea v-if="item.name == 'waterfallArea'" :item="item"></CommodityArea>
    <SwiperArea v-if="item.name == 'swiperArea'" :item="item"></SwiperArea>
    <headerBanner v-if="item.name == 'header-banner'" :item="item"></headerBanner>
    <headerSearch v-if="item.name == 'header-search'" :item="item"></headerSearch>
    <PrefectureCard v-if="item.name == 'prefectureCard'"></PrefectureCard>
    <BankHeader v-if="item.name == 'bank-header'" :item="item"></BankHeader>
    <PriceSearch v-if="item.name == 'price-search'" :item="item"></PriceSearch>
    <BandGoodsArea v-if="item.name == 'bank-goods-area'" :item="item"></BandGoodsArea>
    <CategoryZone v-if="item.name == 'category-zone'" :item="item"></CategoryZone>
    <config-component v-if="item.name == 'config'" :item="item"></config-component>
    <HeaderBannerSwiper v-if="item.name === 'header-banner-swiper'" :item="item"></HeaderBannerSwiper>
    <ImgArea v-if="item.name === 'h5-img-area'" :item="item"></ImgArea>
  </div>
</template>

<script>
import BackGroundImages from "./components/BackGroundImages";
import JinGang from "./components/JinGang";
import PorcelainTileArea from "./components/PorcelainTileArea";
import CommodityArea from "./components/CommodityArea";
import SwiperArea from "./components/SwiperArea";
import BannerArea from "./components/BannerArea";
import headerBanner from "./components/headerBanner";
import headerSearch from "./components/headerSearch";
import PrefectureCard from "./components/PrefectureCard"
import BankHeader from "./components/BankHeader"
import PriceSearch from "./components/PriceSearch";
import BandGoodsArea from "./components/BandGoodsArea";
import GoodsZone from "./components/GoodsZone";
import CategoryZone from "./components/CategoryZone";
import HeaderBannerSwiper from "./components/headerBannerSwiper";
import ImgArea from "@/components/HomeComponents/components/ImgArea/index.vue";
import ConfigComponent from "@/components/HomeComponents/components/ConfigComponent/index.vue";

export default {
  name: "HomeComponents",
  components: {
    ConfigComponent,
    ImgArea,
    BackGroundImages,
    JinGang,
    PorcelainTileArea,
    CommodityArea,
    SwiperArea,
    BannerArea,
    headerBanner,
    headerSearch,
    PrefectureCard,
    BankHeader,
    PriceSearch,
    HeaderBannerSwiper,
    BandGoodsArea, GoodsZone,CategoryZone
  },
  // 组件的props定义,用于子组件接收父组件传值
  props: {
    item: {
      type: Object,
    },
  },
  // 组件的data属性
  data() {
    return {};
  },
  // 组件的方法
  methods: {},
  // 在created钩子中执行的操作
  created() {
  },
};
</script>

<!-- 组件的样式 -->
<style scoped>
</style>
