<template>

</template>
<script>
import {mapMutations} from "vuex";

export default {
  name:"ConfigComponent",
  props: {
    item: {
      type: Object,
    }
  },
  data() {
    return{
        config:{
          hiddenPrice:false,
          priceLength:2
        }
    }
  },
  methods: {
    ...mapMutations(['updateHiddenPrice',"updatePriceLength"])
  },
  mounted() {
    if (this.item.config.hiddenPrice){
      this.updateHiddenPrice(this.item.config.hiddenPrice)
    }
    if (this.item.config.priceLength){
      this.updatePriceLength(this.item.config.priceLength)
    }
  }
}
</script>
