<!-- 商品区组件模板内容 -->
<template>
	<div>
<!--    <div style="margin:0 auto;margin-top: 20px;width: 94.6%;padding: 10px 0;border-radius: 10px;display: flex;align-items: center;font-weight: bold;justify-content: center;background: #fff;">-->
<!--&lt;!&ndash;      <img src="https://yigsj.oss-cn-hangzhou.aliyuncs.com/sj/applet/H5/goodMor.png" width="50%" >&ndash;&gt;-->
<!--&lt;!&ndash;      商品推荐&ndash;&gt;-->
<!--    </div>-->
    <div style="text-align: center">
      <div style="font-size: 18px;font-weight: 400">{{ $t('home.commodityArea.product_recommendations') }}</div>
      <div style="font-size: 9px;margin-top: 4px;color: rgba(97,97,97,0.99)">GUESS YOU LIKE IT</div>
    </div>
    <div style="padding: 10px" v-if="isLoading">
      <van-list style="display: flex;justify-content: space-between;width: 100%;flex-wrap: wrap" >
        <el-skeleton style="width: 49%;margin-bottom: 10px" animated v-for="(item,index) in 10" :key="index">
          <template slot="template">
            <div style="border-radius:10px;background: #fff">
              <div style="padding: 10px">
                <el-skeleton-item variant="image" style="width: 100%; height: 153px;" />
              </div>
              <div style="padding: 14px;">
                <el-skeleton-item variant="p" style="width: 100%" />
                <div
                    style="display: flex; align-items: center; justify-items: space-between;"
                >
                  <el-skeleton-item variant="text" style="margin-right: 16px;" />
                  <el-skeleton-item variant="text" style="width: 30%;" />
                </div>
              </div>
            </div>
          </template>
        </el-skeleton>
      </van-list>
    </div>
		<!--商品列表-->
    <van-list
        v-model="loading"
        :finished="finished"
        :finished-text="$t('home.commodityArea.no_more')"
        @load="load"
    >
<!--      <van-cell v-for="item in list" :key="item" :title="item" />-->
      <GoodsListWaterfall :goods_data_right="goods_data_right" :goods_data_left="goods_data_left" :is-loading="isLoading"></GoodsListWaterfall>
    </van-list>
	</div>
</template>
<script>
// import { findGoodsApi } from '@/api/index';
import GoodsListWaterfall from '@/components/GoodsListWaterfall';
import { getGoodsWithWxshopidApi } from '@/api/index'
import { _debounce } from '@/main';
import { Toast } from 'vant';
export default {
	components: {
		GoodsListWaterfall,
	},
	name: 'CommodityArea',
	// 组件的props定义,用于子组件接收父组件传值
	props: {
		item: {
			type: Object,
		}
	},
	// 组件的data属性
	data() {
		return {
      list: [],
      loading: false,
      finished: false,
			isList: false,
			goods_data: [],
			searchWhere: 1,

			pageIndex: 1,
			goods_data_left: [],
			goods_data_right: [],
			distance: 100,//临界值，距离底部多少距离时触发函数  infiniteHandler
      isLoading: true,
      timeoutObj: null,
		};
	},
	// 组件的方法
	methods: {
    load(){
      if (this.timeoutObj){
        return;
      }

      this.timeoutObj=setTimeout(() => {
        this.timeoutObj=null;
        // 没有数据后，不触发请求
        this.getGoodsApi()
        this.pageIndex += 1
        // 加载状态结束
        this.loading = false;
      }, 1000);
    },
		getGoodsApi: _debounce(function () { //获取商品列表
			let params = {
				wxShopId: localStorage.getItem('shopId'),
				searchWhere: this.searchWhere,
				title: '',
				pageIndex: this.pageIndex,
				pageSize: 10,
        distributorId: localStorage.getItem('distributorId')
			}
			getGoodsWithWxshopidApi(params).then(res => {
				Toast.clear()
				if (res.data.list.length == 0) {
					this.isList = true;
          this.finished = true;
          this.isLoading = false
					return false;
				}
				if (params.pageIndex == 1) {
					window.addEventListener('scroll', this.listenBottomOut)
				}
        if (res.data.list.length<10){
          this.finished = true;
        }
        this.isLoading=false
				this.goods_data = res.data.list
        if (res.data.list.length===0){
          this.finished = true;
        }
				this.searchWhere = res.data.searchWhere
				this.pageIndex = res.data.pageIndex
				for (let i = 0; i < this.goods_data.length; i++) {
					if (this.goods_data_left.length <= this.goods_data_right.length) {
						this.goods_data_left.push(this.goods_data[i])
					} else {
            this.goods_data_right.push(this.goods_data[i])
					}
				}
			}).catch(res => {
				Toast.clear()
			})
		}, 500),
		listenBottomOut() {
			// if (this.isList == false) {
			// 	const scrollTop = document.documentElement.scrollTop || document.body.scrollTop; // 获取页面的可视高度
			// 	const clientHeight = document.documentElement.clientHeight; // 获取页面的总高度
			// 	const scrollHeight = document.documentElement.scrollHeight; // 判断是否滚动到底部，并且不在加载中
			// 	if (scrollTop + clientHeight >= scrollHeight-1) {
			// 		Toast.loading({
			// 			message: '加载中...',
			// 			forbidClick: true,
			// 		});
			// 		// 没有数据后，不触发请求
			// 		this.pageIndex += 1
			// 		this.getGoodsApi()
			// 	}
			// }
		},
	},
	// 在created钩子中执行的操作
	created() {
		//首次查询商品
		this.getGoodsApi()
	},
	// 移除滚动事件监听
	beforeDestroy() {
		window.removeEventListener('scroll', this.handleScroll);
	}
};
</script>

<!-- 组件的样式 -->
<style scoped>
.goods_div {
	width: 100%;
	margin: 40px 0 10px;
}

.gass_like_title {
	font-size: 22px;
	font-weight: bold;
	color: #161616;
	text-align: center;
}

.goods_list_box {
	width: 94%;
	margin: 0px auto;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	justify-content: space-between;
}

.goods_item {
	flex: 1;
	padding: 10px 10px;
	border-radius: 12px;
	margin: 5px 0;
	background-color: #fff;
	width: calc((100% - 10px) / 2);
	min-width: calc((100% - 50px) / 2);
	max-width: calc((100% - 50px) / 2);
}

.goods_img {
	width: 100%;
}

.goods_name {
	font-size: 15px;
	font-weight: bold;
	color: #333333;
	font-family: PingFang SC;
	display: -webkit-box;
	overflow: hidden;
	text-overflow: ellipsis;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}

.goods_price_del {
	font-size: 16px;
	margin-top: 10px;
	color: #A5A5A5;
	text-decoration: line-through;
}

.goods_price {
	font-size: 17px;
	color: #333333;
	font-weight: bold;
}

.goods_loding {
	padding: 12px 0;
	text-align: center;
}
</style>
