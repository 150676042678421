<template>
  <div id="app">

    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"/>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"/>
    <van-tabbar route active-color="#db0320" placeholder v-show="$route.meta.showTabbar" fixed v-if="!isEndTime">
      <van-tabbar-item replace to="/" icon="home-o">{{ $t('app.home' )}}</van-tabbar-item>
      <van-tabbar-item replace to="/shopcar" icon="shopping-cart-o">{{ $t('app.cart' )}}</van-tabbar-item>
      <van-tabbar-item replace to="/user" icon="user">{{ $t('app.me' )}}</van-tabbar-item>
    </van-tabbar>
  </div>
</template>
<style>
* {
  padding: 0;
  margin: 0;
  /*font-family: "Microsoft YaHei", "Heiti SC", tahoma, arial, "Hiragino Sans GB", 宋体, sans-serif;*/
  font-family: -apple-system, Helvetica, sans-serif;
}

.van-nav-bar .van-icon {
  color: #333 !important;
}

.van-nav-bar__text {
  color: #333 !important;
}

.flex_sb {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.oneLineText {
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.toast_title {
  text-align: center;
  font-size: 14px;
  color: #999;
}

.toast_title img {
  width: 30%;
  padding: 1px;
  aspect-ratio: 1/1;
  margin: 20px 0;
}

.flex_no {
  display: flex;
  align-items: center;
}

div {
  background-position: center;
  background-size: 100% 100%;
}
</style>
<script>


export default {
  name: "App",
  components: {},
  data() {
    return {
      isEndTime: this.$store.state.isEndTime
    };
  },
  methods: {},
  created() {
    if (localStorage.getItem("shopTitle")){
      document.title=localStorage.getItem("shopTitle")
    }
    if (localStorage.getItem("shopInfo")){
      let shopInfo=JSON.parse(localStorage.getItem("shopInfo"));
      let indexJson=JSON.parse(shopInfo.indexJson);
      this.$store.commit('updateUnit', shopInfo.unit)
      indexJson.forEach(item=>{
        if (item.name==="config"){
          let {hiddenPrice,priceLength}=item.config;
          this.$store.commit('updateHiddenPrice', hiddenPrice)
          this.$store.commit('updatePriceLength', priceLength)
        }
      })
    }
  }
};
</script>
