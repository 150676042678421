<!-- 组件模板内容 -->
<template>
	<div>
		<van-nav-bar placeholder fixed :title="$t('goodsDetail.goods_detail')" left-arrow @click-left="$router.go(-1)" />
		<van-swipe :autoplay="3000" style="width: 100%;">
			<van-swipe-item v-for="(image, index) in detailData.imgUrlList" :key="index">
				<img v-lazy="image" width="100%" />
			</van-swipe-item>
		</van-swipe>
		<div class="goodsPrice" style="display:flex;align-items:center;justify-content: space-between;">
			<div>
				<div>
					<span>{{ detailData.supplyPrice | decimal }}</span><span>{{ $store.state.unit }}</span>
				</div>
				<div style="font-size:14px;" v-if="!hiddenPrice">
					{{$t('goodsDetail.market_price')}}
          {{detailData.price | decimal }}
          {{$t('goodsDetail.market_price_unit')}}
				</div>
			</div>
			<div class="bijiaBtn" @click="bijiaBtn" v-if="detailData.jdUrl && comparingPrices">
        {{$t('goodsDetail.click_jd_compare')}}
        <van-icon name="arrow"></van-icon>
			</div>
		</div>
		<div class="goodsTitle">
			<!-- <span class="goodsType">
        网易严选
      </span> -->
			<span>
				{{ detailData.title }}
			</span>
		</div>
		<div class="controlsItem" style="margin-top:10px;">
			<div class="title">{{$t('goodsDetail.quantity')}}</div>
			<van-stepper v-model="num" :min="this.detailData.startNum" input-width="24px" button-size="24px" />
		</div>

		<AddressItem :path="'goodsDetail'" @selectAddress="selectAddress"></AddressItem>

		<div class="controlsItem" style="border-top:1px solid #f7f7f7;">
			<div class="title">{{$t('goodsDetail.logistics')}}</div>
			<div style="font-size:14px;color:#666;text-align:right;width:80%;">
				<div>{{ logisticsType }}</div>
				<div v-if="$route.query.isJd == 1" style="white-space:nowrap;text-overflow:ellipsis;overflow:hidden;"
					v-html="predictContent"></div>
				<div v-else style="white-space:nowrap;text-overflow:ellipsis;overflow:hidden;">{{ predictContent }}</div>
			</div>
		</div>
		<div v-if="detailData.categoryAttrList" class="controlsItem" style="margin-top:10px;display:block;">
			<div style="font-size: 14px;margin-bottom:10px;">{{$t('goodsDetail.specification_param')}}</div>
			<div class="table">
				<div class="tableItem" v-for="(item, index) in detailData.categoryAttrList" :key="index">
					<div class="left">{{ item.cateAttrName }}</div>
					<div class="right">
						<span v-for="(it, i) in item.cateAttrValList" :key="i">{{ it }}</span>
					</div>
				</div>
			</div>
		</div>

		<div class="goodsImageDetail" v-if="$route.query.isJd == '1'">
			<div style="margin-bottom:15px;margin-left:5px;font-size:14px;">{{$t('goodsDetail.goods_detail')}}</div>
			<img v-for="(item, index) in detailData.introduceWechat" :key="index" :src="item" alt="">
		</div>
		<div class="goodsImageDetail" v-else>
			<div style="margin-bottom:15px;margin-left:5px;font-size:14px;">{{$t('goodsDetail.goods_detail')}}</div>
			<div v-html="detailData.introduceWechat ? detailData.introduceWechat[0] : ''" style="width:100%;" id="goodsInfo"></div>
		</div>


		<div style="height:50px;"></div>
		<van-goods-action>
			<van-goods-action-icon icon="home-o" :text="$t('app.home' )" to="/" />
			<!-- <van-goods-action-icon icon="chat-o" text="客服" dot />  -->
			<!--dot：红点角标-->
			<van-goods-action-icon icon="cart-o" :text="$t('app.cart' )" to="/shopCar" /> <!--badge：数量角标-->
			<van-goods-action-button type="warning" :text="$t('goodsDetail.add_cart')" @click="addShopcarBtn" />
			<van-goods-action-button type="danger" :text="$t('goodsDetail.buy_now')" @click="byGoods" />
		</van-goods-action>
	</div>
</template>
<style>
.goodsImageDetail img {
	width: 100%;
}
#goodsInfo img{
  padding: 0 !important;
  margin: 0 !important;
  display: block;
}
</style>
<!-- 组件的样式 -->
<style lang="less">
*{
  padding: 0;
  margin: 0;
}
#goodsInfo p{
  padding: 0 !important;
  margin: 0 !important;
  margin-block-start: 0 !important;
  margin-block-end: 0 !important;
}

.goodsPrice {
	width: 100%;
	background-size: 100% 100%;
	background-position: center;
	background: url("https://sjzcsaas.oss-cn-hangzhou.aliyuncs.com/sj/applet/H5/goodsPriceBg.png");
	margin: 0 auto;
	color: #fff;
	padding: 10px;
	box-sizing: border-box;
}

.goodsTitle {
	font-size: 15px;
	background: #fff;
	box-sizing: border-box;
	padding: 10px;
	line-height: 24px;

	.goodsType {
		padding: 2px 0px 2px 2px;
		background: #CA9353;
		border-radius: 3px;
		color: #fff;
		font-size: 14px;
		margin-right: 4px;
	}
}

.controlsItem {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 10px 15px;
	background: #fff;

	.title {
		font-size: 14px;
	}
}

.goodsImageDetail {
	width: 100%;
	padding: 15px 10px;
	background: #fff;
	box-sizing: border-box;
	margin-top: 10px;

	img {
		width: 100% !important;
	}
}

.tableItem {
	border: #c1c1c1 1px solid;
	margin-top: -1px;
	font-size: 12px;
	display: flex;
	box-sizing: border-box;
	line-height: 34px;
	color: #999;

	.left {
		width: 20%;
		height: 34px;
		text-align: center;
		border-right: 1px solid #c1c1c1;
	}

	.right {
		width: 80%;
		height: 34px;
		padding-left: 10px;

		span {
			padding-right: 2px;
		}
	}
}

.bijiaBtn {
	text-align: center;
	border-radius: 20px;
	font-size: 14px;
	color: #fafafa;
	font-weight: 600;
}
</style>
<script>
import {getGoodsDetailApi, addShopcarApi, getGoodsformatApi, getShopTypeApi} from '@/api/index'
import { Toast } from 'vant';
import AddressItem from '@/components/Address/AddressItem';
import axios from "axios";
import {wxShareUtils} from "@/utils/wxShareUtils";
import {mapState} from "vuex";
export default {
	name: "GoodsDetail",
	// 组件的props定义,用于子组件接收父组件传值
	// 组件的data属性
	data() {
		return {
			num: 1,
			detailData: {},
			predictContent: '',
			logisticsType: '',
			comparingPrices: false
		};
	},
  computed: {
    ...mapState(["hiddenPrice"])
  },
	components: { AddressItem },
	// 组件的方法
	methods: {
    async getWxinfo() {
      let {data} = await axios.get("https://h5.3jzc.cn/getWxSignForShare?url=" + location.href);
      let wxInfo = {
        debug: false,
        appId: 'wx3b526f54e8445e92',
        timestamp: data.data.timestamp,
        nonceStr: data.data.noncestr,
        signature: data.data.sign,
        jsApiList: ['updateAppMessageShareData', 'onMenuShareAppMessage', 'updateTimelineShareData']
      }
      let shopInfo = JSON.parse(localStorage.getItem('shopInfo'))
      wxShareUtils(wxInfo, {
        title: shopInfo.name,
        desc: this.$t('goodsDetail.use_jd_redefine_benefit'),
        imgUrl: "https://sjzcsaas.oss-cn-hangzhou.aliyuncs.com/sj/image/jd.jpg",
        link: location.href
      })
    },
		selectAddress(addressId) {
			this.addressId = addressId
			setTimeout(() => {
				this.getGoodsformat()
			}, 500);
		},
		byGoods() {
			localStorage.removeItem("goodsData");
			this.$set(this.detailData, 'num', this.num);
			this.$set(this.detailData, 'ruleType', this.$route.query.ruleType);
			this.$set(this.detailData, 'relationId', this.$route.query.relationId);
			localStorage.setItem("goodsData", JSON.stringify(this.detailData))
			this.$router.push({
				path: '/orderDetail',
				query: {
					path: 'goods',
					addressId: localStorage.getItem('selectAddressData') ?
						JSON.parse(localStorage.getItem('selectAddressData')).id :
						JSON.parse(localStorage.getItem('addressData')) ?
							JSON.parse(localStorage.getItem('addressData')).id : 0
				}
			})
		},
		async getGoodsDetail() {
			let params = {
				id: this.$route.query.id,
				shopId: localStorage.getItem('shopId'),
        distributorId: localStorage.getItem('distributorId'),
				isJd: this.$route.query.isJd,
				ruleType: this.$route.query.ruleType,
				relationId: this.$route.query.relationId,
			}
			let { data } = await getGoodsDetailApi(params)
			this.detailData = data
			this.getGoodsformat()
		},

		async addShopcarBtn() {
			let params = {
				shopId: localStorage.getItem('shopId'),
				skuId: this.detailData.skuId,
				imageUrl: this.detailData.imgUrlList[0],
				num: this.num,
				goodsType: this.$route.query.isJd,
				ruleType: this.$route.query.ruleType,
				relationId: this.$route.query.relationId,
			}
			try {
				let res = await addShopcarApi(params)
				if (res.code == 200) {
					Toast.success(this.$t('goodsDetail.add_cart_success'))
				}
			} catch (error) {

			}
		},
		async getGoodsformat() {
			let params = {
				goodsId: this.detailData.skuId,
				addressId: this.addressId,
				num: this.num,
				goodsType: this.$route.query.isJd,
			}
			let { data } = await getGoodsformatApi(params)
			this.predictContent = data.predictContent
			this.logisticsType = data.logisticsType
		},

		bijiaBtn() {
			// console.log(this.detailData);
			if (this.detailData.jdUrl) {
				window.location.href = this.detailData.jdUrl
			}
		},
    async getShopInfo(){
      let {data} = await getShopTypeApi({shopId: localStorage.getItem('shopId')})
      document.title = data.shopInfo.name
      this.$store.commit('updateUnit', data.shopInfo.unit) //将店铺所用单位存储到vuex
      localStorage.setItem('unit', data.shopInfo.unit)
      localStorage.setItem('shopInfo', JSON.stringify(data.shopInfo))
      this.comparingPrices = JSON.parse(localStorage.getItem('shopInfo')).comparingPrices
    }
	},


	// 在created钩子中执行的操作
	created() {
    if (this.$route.query.shopId){
      localStorage.setItem('shopId', this.$route.query.shopId)
      this.addressId = localStorage.getItem('selectAddressData') ?
          JSON.parse(localStorage.getItem('selectAddressData')).id :
          JSON.parse(localStorage.getItem('addressData')) ?
              JSON.parse(localStorage.getItem('addressData')).id : 0
      if (!localStorage.getItem('shopInfo')){
        setTimeout(() => {
          this.getShopInfo()
        },1000)
      }
      this.getGoodsDetail()

    }
		this.addressId = localStorage.getItem('selectAddressData') ?
			JSON.parse(localStorage.getItem('selectAddressData')).id :
			JSON.parse(localStorage.getItem('addressData')) ?
				JSON.parse(localStorage.getItem('addressData')).id : 0
		this.comparingPrices = JSON.parse(localStorage.getItem('shopInfo')).comparingPrices
		this.getGoodsDetail()
	},
};
</script>

