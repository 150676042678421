<!-- 组件模板内容 -->
<template>
  <div class="homeComponent">
    <van-empty image="error" :description="$t('homeView.shop_no_exist_or_expired')" v-if="isEndTime"/>
    <van-empty image="error" :description="$t('homeView.shop_stop')" v-if="!isEndTime && isStop"/>
    <template v-else>
      <HomeComponents v-for="(item, index) in componentList" :key="index" :item="item"></HomeComponents>
    </template>

    <div style="height:50px;"></div>
  </div>
</template>

<script>
import HomeComponents from "@/components/HomeComponents";
import {getShopTypeApi} from '@/api/index'
import axios from "axios";
import {wxShareUtils} from "@/utils/wxShareUtils";

export default {
  name: "HomeView",
  components: {
    HomeComponents
  },
  // 组件的data属性
  data() {
    return {
      componentList: [],
      shopInfo: {},
      isEndTime: false,
      isStop:false
    };
  },
  // 组件的方法
  methods: {
    async getShopType() {  //获取店铺信息
      let params = {
        shopId: localStorage.getItem('shopId'),
        distributorId: localStorage.getItem('distributorId')
      }
      let {data} = await getShopTypeApi(params)
      let {endTime,status}=data.shopInfo;
      if (+new Date() > +new Date(endTime)) {
        this.isEndTime = true;
        this.$store.commit('updateIsEndTime', true)
        return false
      }
      if (status===2){
        this.isStop=true
      }
      this.isEndTime = false;
      document.title = data.shopInfo.name
      localStorage.setItem("shopTitle",data.shopInfo.name)
      this.shopInfo = data.shopInfo
      this.$store.commit('updateUnit', data.shopInfo.unit) //将店铺所用单位存储到vuex
      localStorage.setItem('unit', data.shopInfo.unit)
      localStorage.setItem('shopInfo', JSON.stringify(data.shopInfo))
      this.componentList = JSON.parse(data.shopInfo.indexJson)
      this.getWxinfo()
    },
    async getWxinfo() {
      let {data} = await axios.get("https://h5.3jzc.cn/getWxSignForShare?url=" + location.href);
      let wxInfo = {
        debug: false,
        appId: 'wx3b526f54e8445e92',
        timestamp: data.data.timestamp,
        nonceStr: data.data.noncestr,
        signature: data.data.sign,
        jsApiList: ['updateAppMessageShareData', 'onMenuShareAppMessage', 'updateTimelineShareData']
      }
      let shopInfo = JSON.parse(localStorage.getItem('shopInfo'))
      wxShareUtils(wxInfo, {
        title: shopInfo.name,
        desc: this.$t('homeView.use_jd_redefine_benefit'),
        imgUrl: "https://sjzcsaas.oss-cn-hangzhou.aliyuncs.com/sj/image/jd.jpg",
        link: location.href
      })
    },
  },
  // 在created钩子中执行的操作
  created() {
    if (this.$route.query.distributorId){
      localStorage.setItem('distributorId', this.$route.query.distributorId)
    }
    if (this.$route.query.shopId == undefined && !localStorage.getItem('shopId')) {
      this.isEndTime = true;
      this.$store.commit('updateIsEndTime', true)
      return false
    } else if (this.$route.query.shopId != undefined) {
      if (localStorage.getItem('shopId') && localStorage.getItem('shopId') != this.$route.query.shopId) {
        localStorage.removeItem('userToken')
        localStorage.removeItem('addressData')
        localStorage.removeItem('selectAddressData')
        localStorage.removeItem('unit')
        localStorage.removeItem('distributorId')
        localStorage.setItem('shopId', this.$route.query.shopId)
        //只有shopId变了后才设置
        if (this.$route.query.distributorId != undefined){
          localStorage.setItem('distributorId', this.$route.query.distributorId)
        }
      }


      this.$store.commit('updateIsEndTime', false)
      localStorage.setItem('shopId', this.$route.query.shopId)
      this.getShopType()
    }else{
      this.getShopType()
    }
  },
};
</script>

<!-- 组件的样式 -->
<style scoped>
.homeComponent {
  /*background: linear-gradient(#df5835, 25%, #ffdfd7) !important;*/
  background: #F6F6F6;
}
</style>
