import axios from 'axios'
import router from '@/router'
import {Dialog, Toast} from 'vant'
import i18n from "@/i18n";
// create an axios instance
const service = axios.create({
    baseURL: 'https://saas.3jzc.cn/api',
    // baseURL: 'http://127.0.0.1:10001',
    // baseURL: '/api',
    timeout: 50000 // request timeout
})


service.interceptors.request.use(
    config => {
        if (config.method === 'post' && config.data instanceof FormData) {
            config.headers['Content-Type'] = 'multipart/form-data';
        } else {
            config.headers['Content-Type'] = 'application/json;charset=UTF-8';
        }
        if (config.isShow == true) {
            Toast.loading({
                message: i18n.t("request.loading"),
                forbidClick: true,
            });
        }
        if (localStorage.getItem("userToken")) {
            config.headers['userToken'] = localStorage.getItem("userToken");
        }
        return config
    },
    error => {
        return Promise.reject(error)
    }
)


service.interceptors.response.use(
    response => {
        Toast.clear()
        const res = response.data
        if (res.code == undefined) {
            return res
        }
        if (res.code !== 200) {
            return Promise.reject(res)
        } else {
            return res;
        }
    },
    error => {
        Toast.clear()
        console.log(i18n.t("request.tip"))
        let data = error.response.data
        if (data.msg == '用户未登录' || data.msg == 'toKen已过期') {
            Dialog.confirm({
                title: i18n.t("request.tip"),
                message: i18n.t("request.no_login"),
            }).then(() => {
                router.push('/login')
            }).catch(() => {
                router.push('/')
            })
        }
        return Promise.reject(error)
    }
)
export default service
