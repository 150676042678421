export const app={
    "home":"首页",
    "cart":"购物车",
    "me":"我的"
}

export const home = {
    backgroundImages: {
        search: "搜索",
        search_like_goods: "搜想要的商品"
    },
    bandGoodsArea: {
        buy_now: "立即购买",
        view_more: "查看更多",
    },
    bankHeader: {
        search: "搜索",
        input_keyword_search: "请输入关键字搜索"
    },
    commodityArea: {
        product_recommendations: "商品推荐",
        no_more: "没有更多了"
    },
    headerSearch: {
        search_like_goods: "搜想要的商品",
        search: "搜索"
    },
    jinGang: {
        more: "更多"
    },
    porcelainTileArea: {
        priority_zone: "优选专区",
        select_good_quality_goods: "精选优质好物",
        stay_tuned: "敬请期待",
        proprietary_area: "自营专区",
        genuine_guarantee_jd_logistics: "正品保障 京东物流"
    },
    prefectureCard: {
        loadImg: {
            view_more: "查看更多 >"
        }
    },
    priceSearch:{
        price_range:"价格区间",
        price_unit:"元",
        search: "搜索",
        and_above:"及以上"
    }
}

export const address = {
    addressList:{
        address_list:"地址列表",
        default:"默认",
        no_address_info:"暂无地址信息",
        add_address:"新增地址",
        create_address:"创建地址",
        tips:"提示",
        confirm_delete:"确定删除该地址吗？",
        delete_success:"删除成功",
    },
    createAddress:{
        receiver_name:"收货人姓名",
        input_receiver_name:"请输入收货人姓名",
        receiver_contact_info:"联系方式",
        input_receiver_contact_info:"请输入收货人手机号",
        province_city_area:"省市区",
        choose_province_city_area:"请选择省市区",
        choose_your_region:"请选择所在地区",
        address_detail:"详细地址",
        input_address_detail:"请输入详细地址",
        set_it_as_default:"设为默认地址",
        confirm:"确认",
        create_address_success:"地址添加成功",
    },
    editAddress:{
        edit_address:"编辑地址",
        receiver_name:"收货人姓名",
        input_receiver_name:"请输入收货人姓名",
        receiver_contact_info:"联系方式",
        input_receiver_contact_info:"请输入收货人手机号",
        province_city_area:"省市区",
        choose_province_city_area:"请选择省市区",
        choose_your_region:"请选择所在地区",
        address_detail:"详细地址",
        input_address_detail:"请输入详细地址",
        set_it_as_default:"设为默认地址",
        confirm:"确认",
        edit_address_success:"地址编辑成功",
    },
    addressItem:{
        choose_address:"请选择地址",
        default: "默认",
        deliver_to: "送至",
        deliver_to2:"配送至",
        create_address:"创建地址",
        add_address: "新增地址",
        edit_address:"编辑地址",
    }
}

export const exchange = {
    exchange_balance:"兑换余额",
    input_card_secret:"请输入卡密",
    input_tel:"请输入手机号",
    confirm:"确定",
    tips_1:"友情提示：",
    tips_2:"1、请填写会员卡上卡密。",
    tips_3:"2、请确认您的卡密，确认后不可更改。",
    tips_4:"3、兑换成功后，在【我的】中查看余额明细。",
    tips_5:"4、余额兑换后五年内有效。",

}
export const record={
    exchangeRecord:{
        exchange_detail:"兑换明细",
        user_recharge:"用户充值",
        backend:"后台",
        adjust:"调整",
        shopping_use:"购物使用",
        refund_increase:"退单增加",
        no_exchange_detail:"暂无兑换明细",
    },
    orderRecord:{
        my_order:"我的订单",
        refund:"退款",
        wait_send_goods:"待发货",
        wait_receipt:"待收货",
        completed:"已完成",
        confirm_receipt:"确认收货",
        view_logistics_info:"查看物流信息",
        apply_afs:"申请售后",
        view_process:"查看进度",
        reapply_afs:"重新申请售后",
        no_relevant_order:"暂无相关订单",
        apply_refund_success:"申请退款成功",
        ask_confirm_receipt:"确认收到货了吗？",
        whether_goods_received:"是否已经收到货？",
        confirm_receipt_success:"确认收货成功",
        confirm_receipt_fail:"确认收货失败",
        receipt_completed:"收货完成",
        receipt_fail_wait_try_again:"收货失败，请稍后重试",
    }
}
export const beforeSearch={
    search:"搜索",
    back:"返回",
    input_goods_name:"请输入商品名称",
    search_history:"搜索历史",
    no_search_history:"暂无搜索历史",
    tips:"提示",
    ask_confirm_delete_history:"是否确认清空搜索历史？"
}

export  const homeView={
    shop_no_exist_or_expired:"商城不存在或已过期",
    use_jd_redefine_benefit:"\n用京东重新定义福利",
    shop_stop:"门店已停用"

}

export const goodsDetail={
    goods_detail:"商品详情",
    market_price:"市场价:",
    market_price_unit:"元",
    click_jd_compare:"点击前往京东比价",
    quantity:"数量",
    logistics:"物流",
    specification_param:"规格参数",
    add_cart:"加入购物车",
    buy_now:"立即购买",
    use_jd_redefine_benefit:"\n用京东重新定义福利",
    add_cart_success:"添加购物车成功",
}

export const login = {
    login_registration: "登录注册",
    tel: "手机号",
    please_input_tel: "请输入手机号",
    sms_verification_code: "短信验证码",
    input_sms_verification_code: "请输入短信验证码",
    send_sms_verification_code: "发送短信验证码",
    login: "登录",
    register: "注册",
    code_invalid_obtain_again: "验证码已失效，请重新获取",
    input_correct_tel: "请输入正确的手机号",
    forget_password: "忘记密码",
}

export  const logistics={
    logistics_info:"物流信息",
    no_logistics_info:"暂无物流信息",
    copy_success:"复制成功",
    logistics_company:"物流公司",
    logistics_number:"物流单号",
    logistics_status:"物流状态",
}

export const orderDetail={
    write_order:"填写订单",
    total_txt:"共",
    unit:"件",
    goods_money:"商品金额",
    freight:"运费",
    total_money_txt:"合计：",
    submit_order:"提交订单",
    goods_list:"商品清单",
    payment:"去支付",
    payment_success:"支付成功",
    payment_fail_and_try_again_or_kefu:"支付失败，请联系客服或稍后重试",
    add_receipt_address:"请先添加收货地址",
    shop_no_exist_or_expired:"商城不存在或已过期",
    no_shop:"门店不存在",
    shop_stop:"门店已经停用"
}
export const paySuccess = {
    pay_success:"支付成功",
    back_home:"返回首页",
    view_detail:"查看详情",
}

export const refund = {
    apply_refund:"申请退款",
    refund_reason:"退款原因",
    input_refund_reason:"请输入退款原因",
    total_txt:"共",
    unit:"件",
    refund_info:"退款信息",
    choose_refund_reason:"请选择退款原因",
    refund_amount:"退款金额",
    refund_amount_Tips:"不可修改,退款金额将原路返回至您的账户",
    money_unit:"元",
    afs_type:"售后类型",
    choose_afs_type:"请选择",
    choose_afs_type_2:"请选择售后类型（必填）：",
    choose_afs_type_3:"请选择售后类型",
    only_support_return_goods_and_money:"当前仅支持退货退款",
    add_descriptions_credential:"补充描述和凭证",
    add_descriptions_credential_tips:"补充描述，有助于商家更好的处理售后问题~",

    return_method:"退货方式",
    choose_return_method:"请选择",
    choose_return_method_2:"请选择退货方式（必填）：",
    return_goods:"退货",
    return_goods_and_money:"退货退款",
    return_goods_and_money_tips:"仅支持退货退款",
    my_address:"我的地址",
    home_pick_up_time:"上门时间",
    submit_refund:"提交",

    cancel_order:"取消订单",
    warm_reminder:"温馨提示",
    warm_reminder_tip_1:"1.消费者因个人原因申请退货，包邮商品需承担返回运费， 非包邮商品需承担送返两程运费",
    warm_reminder_tip_2:"2.订单一旦取消，支付优惠也一并取消",
    warm_reminder_tip_3:"3.退款金额将原路返回至您的支付账户",
    cancel_order_reason:"请选择取消订单的原因（必填）：",

    confirm:"确定",
    choose_home_pick_up_time:"选择时间",
    choose_home_pick_up_time_2:"请选择取件时间",
    deliver_to:"配送至",
    add_address:"新增地址",
    create_address:"创建地址",
    goods_no_stock:"商品库存不足",
    send_goods_question:"商品发货问题",
    dont_want:"不想要了",
    mis_multiple_selection:"商品错选/多选",
    seven_days_no_reason:"七天无理由退货",
    price_reduction:"商品降价",
    return_goods_refund:"退货退款",

    door2door_pickup:"上门取件",
    customer_send:"客户送货",
    customer_delivery:"客户发货",
    support_seven_day_no_reason:"支持7天无理由退货",
    only_refund:"仅退款",
    submit_success:"提交成功",
    input_question_remark:"请输入问题描述",
    upload_least_one_picture:"请至少上传一张问题图片",
    choose_pickup_address:"请选择取件地址",
}

export const refunding = {
    view_progress:"查看进度",
    refund_info:"退款信息",
    refund_amount: "退款金额",
    refund_reason:"退款原因",
    apply_time:"申请时间",
    order_no:"订单编码",
    return_method:"退货方式",
    my_address:"我的地址",
    door2door_pickup_time:"上门时间",
    delivery_address:"收货地址",
    copy:"复制",
    upload_logistics_no:"上传单号",
    logistics_company:"物流公司：",
    logistics_number:"物流单号：",
    send_ok_go_fill_it:"我已寄出，去填写物流单号",
    fill_logistics_number:"填写单号",
    refund_to_account:"退款到账",
    money_unit:"元",
    rejection_grounds:"驳回理由",
    confirm_completion:"确认完成",
    contact_customer_service:"联系客服介入",
    input_logistics_number:"填写物流单号",
    please_input_logistics_number:"请填写物流单号",
    freight:"运费",
    input_freight:"请输入运费金额",
    input_logistics_company:"请输入物流公司：",
    confirm:"确认",
    contact_afs:"联系售后",

    wait_merchant_review:"待商家审核",
    wait_goods_return:"待寄回商品",
    pending_merchant_process:"待商家处理",
    completed:"已完成",
    cancelled:"已取消",
    reject:"驳回",
    submit_logistics_info_ok:"提交物流信息成功",
    submit_success:"提交成功",
    copy_success:"复制成功",
    no_support_copy:"该浏览器不支持自动复制"


}

export  const  search = {
    proprietary_area : "自营专区",
    priority_zone:"优选专区",
    search:"搜索",
    back:"返回",
    no_more:"没有更多了",
    input_goods_name:"请输入商品名称",
    all:"全部",
    loading:"加载中..."

}

export const shopCar = {

    jd:"JD京东",
    sj:"官方自营",
    delete:"删除",
    empty:"购物车空空如也~",
    select_all:"全选",
    next_step:"下一步",
    tips:"提示",
    ask_confirm_delete:"是否确认删除该商品？",
    delete_success:"删除成功",
    no_buy_goods:"您还没有选购商品",
    no_receipt_address:"您还没有收获地址",
    shop_no_exist_or_expired:"商城不存在或已过期",
    shop_stop:"门店已停用"
}
export const topicDetail = {
    no_more:"没有更多了",
}
export const user = {
    person_center:"个人中心",
    person_info:"个人信息",
    my:"我的",
    exchange_now:"立即兑换",
    my_order:"我的订单",
    wait_send_goods:"待发货",
    wait_receipt:"待收货",
    completed:"已完成",
    my_service:"我的服务",
    exchange_detail:"兑换明细",
    address_manage:"地址管理",
    contact_kf:"联系客服",
    contact_afs:"联系售后",
    kf_tel:"客服联系方式",
    update_person_info:"修改个人信息",
    user_name:"用户名",
    input_user_name:"请输入用户名",
    logout:"退出登录",
    head_img:"头像",
    save:"保存",
    click_call:"(点击拨号)",
    update_user_info_success:"修改用户信息成功",
    tips:"提示",
    ask_confirm_logout:"是否确认退出登录？",
}

export const goodsListWaterFall = {
    no_goods_exist:"很抱歉，商品不存在",
    change_keyword_or_try_again:"您可更换关键词搜索或稍后再试",
    loading:"加载中...",
    money_unit:"元"
}

export const searchComponent = {
    search:"搜索",
    input_keyword_search:"请输入关键字搜索",
    no_goods_exist:"很抱歉，商品不存在",
    change_keyword_or_try_again:"您可更换关键词搜索或稍后再试",
    loading:"加载中..."
}

export const request = {
    loading:"加載中...",
    no_login:"用户未登录,是否前往登录",
    tip:"提示"
}
