<!-- 搜索查询/分类查询 -->
<template>
  <div class="searchContent">
    <van-nav-bar placeholder fixed
                 v-if="!$route.query.navTitle"
                 :title="$route.query.title?$route.query.title:$route.query.isJd == 1 ? $t('search.proprietary_area') : $route.query.isJd == 2 ?
                  $t('search.priority_zone') : $t('search.search')"
                 :left-text=" $t('search.back')" left-arrow @click-left="$router.go(-1);"/>
    <van-nav-bar placeholder fixed
                 v-else
                 :title="$route.query.navTitle"
                 :left-text=" $t('search.back')" left-arrow @click-left="$router.go(-1);"/>

    <div style="width:100%;background:#fff;padding-top:10px;" v-if="!$route.query.isJd">
      <!--搜索框组件-->
      <div class="search_wrap" style="background:#dadada;" @click="$router.push('/beforeSearch')">
        <div class="input_wrap">
          <van-icon name="search" size="18"/>
          <input type="text" :placeholder="$t('search.input_goods_name')" style="background:#dadada;" :value="$route.query.title">
        </div>
        <div class="search_btn">{{ $t('search.search')}}</div>
      </div>
      <!--横向滚动分类组件-->
      <van-tabs sticky style="width:100%;" @click="cateChange" v-model="activeName" v-if="!$route.query.noCategory">
        <van-tab v-for="(item, index) in cate_data" :key="index" :title="item.fullName">
        </van-tab>
      </van-tabs>
    </div>
    <div style="padding: 10px" v-if="isLoading">
      <van-list style="display: flex;justify-content: space-between;width: 100%;flex-wrap: wrap" >
        <el-skeleton style="width: 49%;margin-bottom: 10px" animated v-for="(item,index) in 10" :key="index">
          <template slot="template">
            <div style="border-radius:10px;background: #fff">
              <div style="padding: 10px">
                <el-skeleton-item variant="image" style="width: 100%; height: 153px;" />
              </div>
              <div style="padding: 14px;">
                <el-skeleton-item variant="p" style="width: 100%" />
                <div
                    style="display: flex; align-items: center; justify-items: space-between;"
                >
                  <el-skeleton-item variant="text" style="margin-right: 16px;" />
                  <el-skeleton-item variant="text" style="width: 30%;" />
                </div>
              </div>
            </div>
          </template>
        </el-skeleton>
      </van-list>
    </div>
    <van-list
        v-model="loading"
        :finished="finished"
        :finished-text="$t('search.no_more')"
        @load="load"
    >
      <GoodsListWaterfall :goods_data_left="goods_data_left" :goods_data_right="goods_data_right"
                          :isLoading="isLoading">
      </GoodsListWaterfall>
    </van-list>

    <div style="height:50px;"></div>
  </div>
</template>
<style scoped>
.search_wrap {
  width: 94.6%;
  aspect-ratio: 10/1;
  background: #fff;
  display: flex;
  align-items: center;
  padding: 4px 10px;
  padding-right: 0;
  border-radius: 20px;
  box-sizing: border-box;
  margin: 0 auto;
}

.input_wrap {
  display: flex;
  align-items: center;
  width: 80%;
  height: 100%;
}

.search_wrap input {
  width: 100%;
  height: 100%;
  border: 0;
  margin-left: 8px;
  color: #666;
  font-size: 14px;
}

.search_btn {
  width: 20%;
  height: 28px;
  line-height: 28px;
  text-align: center;
  font-size: 14px;
}
</style>
<script>
import GoodsListWaterfall from '@/components/GoodsListWaterfall';
import SearchComponents from '@/components/SearchComponents';
import CateComponents from '@/components/CateComponents';
import {getGoodsWithWxshopidApi, getCateDataApi, getActiveGoodsApi} from '@/api/index'
import {_debounce} from '@/main';
import {Toast} from 'vant';

export default {
  components: {
    GoodsListWaterfall,
    SearchComponents,
    CateComponents
  },
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      goods_data: [],
      cate_data: [],
      goods_data_left: [],
      goods_data_right: [],
      searchWhere: 1,
      pageIndex: 1,
      categoryId: '',
      categoryLevel: 1,
      activeName: 0,
      isList: false,
      isJd: '',
      minSalePrice: '',
      maxSalePrice: '',
      isLoading: true,
      topicId: ""
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {
    '$route'(to, from) {
      // 判断是否是从商品列表进入商品详情页面
      if (from.name === "home") {
        location.reload();
      }
    }
  },
  //方法集合
  methods: {
    load(){
      setTimeout(() => {
        // 没有数据后，不触发请求
        this.pageIndex += 1
        this.getGoodsApi()
        // 加载状态结束
        this.loading = false;
      }, 1000);
    },
    initGoodsData() { //瀑布流数据初始化 分左右列
      for (let i = 0; i < this.goods_data.length; i++) {
        if (this.goods_data_left.length <= this.goods_data_right.length) {
          this.goods_data_left.push(this.goods_data[i])
        } else {
          this.goods_data_right.push(this.goods_data[i])
        }
      }
    },

    async getCateData() {
      let {data} = await getCateDataApi({
        shopId: localStorage.getItem('shopId'),
        categoryLevel: localStorage.getItem('categoryLevel') * 1,
        distributorId: localStorage.getItem('distributorId')
      })
      this.cate_data = data
      this.cate_data.unshift({
        categoryId: '',
        fullName: this.$t('search.all'),
      })
    },
    getGoodsApi: _debounce(function () {
      let params = {}
      if (this.$route.query.isJd == 1 || this.$route.query.isJd == 2) {
        params = {
          wxShopId: localStorage.getItem("shopId"),
          distributorId: localStorage.getItem('distributorId'),
          searchWhere: this.searchWhere,
          isJd: this.$route.query.isJd,
          pageIndex: this.pageIndex,
          pageSize: 10,
          minSalePrice: this.minSalePrice,
          maxSalePrice: this.maxSalePrice,
        }
      } else {
        params = {
          wxShopId: localStorage.getItem('shopId'),
          distributorId: localStorage.getItem('distributorId'),
          searchWhere: this.searchWhere,
          title: this.$route.query.title || '',
          pageIndex: this.pageIndex,
          pageSize: 10,
          categoryId: this.categoryId,
          categoryLevel: this.categoryLevel,
          minSalePrice: this.minSalePrice,
          maxSalePrice: this.maxSalePrice,
        }
      }
      if (this.$route.query.topicId) {
        this.topicId = this.$route.query.topicId
        params.topicId = this.$route.query.topicId
        getActiveGoodsApi(params).then(res => {
          this.isLoading = false

          Toast.clear()
          if (res.data.list.length == 0) {
            this.isList = true;
            this.finished = true;
            this.isLoading = false
            return false;
          }
          if (params.pageIndex == 1) {
            window.addEventListener('scroll', this.listenBottomOut)
          }
          this.goods_data = res.data.list
          this.searchWhere = res.data.searchWhere
          this.pageIndex = res.data.pageIndex
          this.initGoodsData()
          this.isLoading = false
          if (res.data.list.length<10){
            this.finished = true;
          }
        }).catch(res => {
          Toast.clear()
        })
      } else {
        this.topicId = ""
        getGoodsWithWxshopidApi(params).then(res => {
          this.loading = false;
          this.finished = false;
          Toast.clear()
          if (res.data.list.length == 0 ) {
            this.isList = true;
            this.finished = true;
            this.isLoading = false
            return false;
          }
          if (params.pageIndex == 1) {
            window.addEventListener('scroll', this.listenBottomOut)
          }
          this.goods_data = res.data.list
          this.searchWhere = res.data.searchWhere
          this.pageIndex = res.data.pageIndex
          this.initGoodsData()
          this.isLoading = false
          if (res.data.list.length<10){
            this.finished = true;
          }
          return;
        }).catch(res => {
          Toast.clear()
        })
      }

    }, 100),
    listenBottomOut:_debounce(function (){
      // const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      // const clientHeight = document.documentElement.clientHeight
      // const scrollHeight = document.documentElement.scrollHeight
      // if (scrollTop + clientHeight >= scrollHeight - 1 && this.isList == false) {
      //   Toast.loading({
      //     message: '加载中...',
      //     forbidClick: true,
      //   });
      //   // 没有数据后，不触发请求
      //   this.pageIndex += 1
      //   this.getGoodsApi()
      // }
    }, 500) ,

    cateChange(e) {
      Toast.loading({
        message: this.$t('search.loading'),
        forbidClick: true,
      })
      this.activeName = e
      this.categoryId = this.cate_data[e].categoryId
      this.categoryLevel = this.cate_data[e].categoryLevel
      this.goods_data = []
      this.goods_data_left = []
      this.goods_data_right = []
      this.pageIndex = 1
      this.searchWhere = 1
      this.isList = false
      this.isLoading = true
      this.getGoodsApi()
    },
  },
  beforeCreate() {
  }, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {

    this.categoryIdQuery = this.$route.query.categoryId || ''
    this.activeName = (this.$route.query.activeName * 1) + 1 || 0
    this.categoryId = this.$route.query.categoryId || ''
    this.categoryLevel = this.$route.query.categoryLevel
    this.isJd = this.$route.query.isJd
    this.getCateData()
    this.getGoodsApi()
    // this.initGoodsData()
  },
  beforeMount() {
  }, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {

  },
  beforeUpdate() {
  }, //生命周期 - 更新之前
  updated() {

  }, //生命周期 - 更新之后
  beforeDestroy() {
  }, //生命周期 - 销毁之前
  destroyed() {
    // window.removeEventListener('scroll', this.listenBottomOut, false)
  }, //生命周期 - 销毁完成
  activated() {
    this.isLoading = true
    this.isList = false
    if (this.$route.query.pageIndex) {
      this.pageIndex = this.$route.query.pageIndex
    }
    if (this.categoryIdQuery == '') {
      this.categoryIdQuery = this.$route.query.categoryId
      Toast.loading({
        message: this.$t('search.loading'),
        forbidClick: true,
      });
    }
    if (this.maxSalePrice != this.$route.query.maxSalePrice || this.minSalePrice != this.$route.query.minSalePrice) {
      Toast.loading({
        message: this.$t('search.loading'),
        forbidClick: true,
      });
      this.goods_data = []
      this.goods_data_left = []
      this.goods_data_right = []
      this.pageIndex = 1
      this.searchWhere = 1
      this.maxSalePrice = this.$route.query.maxSalePrice
      this.minSalePrice = this.$route.query.minSalePrice
      this.categoryId = this.$route.query.categoryId || ''
      this.activeName = (this.$route.query.activeName * 1) + 1 || 0
      this.categoryLevel = this.$route.query.categoryLevel
      this.getGoodsApi()
    }
    if (this.categoryIdQuery != this.$route.query.categoryId && this.$route.query.categoryId != undefined) {
      Toast.loading({
        message: this.$t('search.loading'),
        forbidClick: true,
      });
      this.goods_data = []
      this.goods_data_left = []
      this.goods_data_right = [],
          this.pageIndex = 1
      this.searchWhere = 1
      this.maxSalePrice = this.$route.query.maxSalePrice
      this.minSalePrice = this.$route.query.minSalePrice
      this.categoryId = this.$route.query.categoryId || ''
      this.activeName = (this.$route.query.activeName * 1) + 1 || 0
      this.categoryLevel = this.$route.query.categoryLevel
      this.getGoodsApi()
    } else if (this.isJd != this.$route.query.isJd) {
      Toast.loading({
        message: this.$t('search.loading'),
        forbidClick: true,
      });
      this.goods_data = []
      this.goods_data_left = []
      this.goods_data_right = []
      this.categoryId = ''
      this.pageIndex = 1
      this.searchWhere = 1
      this.getGoodsApi()
    } else if (this.$route.query.title) {
      Toast.loading({
        message: this.$t('search.loading'),
        forbidClick: true,
      });
      this.goods_data = []
      this.goods_data_left = []
      this.goods_data_right = []
      this.activeName = (this.$route.query.activeName * 1) + 1 || 0
      this.categoryId = ''
      this.pageIndex = 1
      this.searchWhere = 1
      this.getGoodsApi()
    }
  },
}
</script>
<style>
body {
  background: #f7f7f7;
}
</style>
