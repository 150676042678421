import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from "@/i18n";
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';

import getBrowserLanguage from "@/utils/getBrowserLanguage";

//vant
import vant, { Lazyload } from 'vant'
import 'vant/lib/index.css';


Vue.use(vant)
Vue.use(Lazyload);
Vue.use(Element);

if (!localStorage.getItem("lang")){
	if (getBrowserLanguage()==='zh_TW'){
		localStorage.setItem("lang","zh_TW")
	}else{
		localStorage.setItem("lang", "zh_CN")
	}
}

// 定义全局过滤器
Vue.filter('decimal', function (value) {
	// 判断是否为数字
	if (isNaN(value)) {
		return value;
	}
	// 保留两位小数
	return parseFloat(value).toFixed(store.state.priceLength);
});
Vue.filter('jdUrl', function (value) {
	if (value.indexOf("https://yigsj.oss-cn-hangzhou.aliyuncs.com/")===0){
		return value.replace("https://yigsj.oss-cn-hangzhou.aliyuncs.com/", "https://sjzcsaas.oss-cn-hangzhou.aliyuncs.com/");
	}
	if (value.indexOf("https://img")===0){
		//value分割
		let arr = value.split("/");
		arr[3]="n1"
		return arr.join("/");
	}
	return value;
});

Vue.config.productionTip = false

new Vue({
	router,
	store,
	i18n,
	render: h => h(App)
}).$mount('#app')


//节流
export function _throttle(fn, wait = 200) {
	let last, timer, now;
	return function () {
		now = Date.now();
		if (last && now - last < wait) {
			clearTimeout(timer);
			timer = setTimeout(function () {
				last = now;
				fn.call(this, ...arguments);
			}, wait);
		} else {
			last = now;
			fn.call(this, ...arguments);
		}
	};
}


// 防抖
export function _debounce(fn, wait = 200) {
	let timer;
	return function () {
		let context = this;
		let args = arguments;
		if (timer) clearTimeout(timer);
		timer = setTimeout(() => {
			fn.apply(context, args);
		}, wait)
	}
}
