<template>
	<div class="createAddress" style="height:450px;">
		<van-field v-model="userName" :label="$t('address.createAddress.receiver_name')"
               :placeholder="$t('address.createAddress.input_receiver_name')" />
		<van-field v-model="tel" type="tel" :label="$t('address.createAddress.receiver_contact_info')"
               :placeholder="$t('address.createAddress.input_receiver_contact_info')" />

		<!--选择省市区部分-->
		<van-field @click-input="clickinput" type="tel" :label="$t('address.createAddress.province_city_area')"
               :placeholder="$t('address.createAddress.choose_province_city_area')"
               v-model="provincesAndCity" readonly/>
		<van-action-sheet v-model="show" style="height:480px;">
			<van-cascader v-model="cascaderValue" :title="$t('address.createAddress.choose_your_region')" :options="areaList" @close="show = false"
				@change="onChange" @finish="onFinish" :field-names="fieldNames" />
		</van-action-sheet>

		<!--选择街道部分-->
		<!-- <van-field @click-input="clickinput_street" type="tel" label="街道" placeholder="请选择街道" v-model="streewz" />
			<van-action-sheet v-model="show_street">
				<van-picker title="选择街道" show-toolbar :columns="streetList" @cancel="cancelStreet"
					@confirm="confirmStreet" />
			</van-action-sheet> -->

		<van-field v-model="addressDetail" type="text" :label="$t('address.createAddress.address_detail')"
               :placeholder="$t('address.createAddress.input_address_detail')" />

		<van-cell center :title="$t('address.createAddress.set_it_as_default')">
			<template #right-icon>
				<van-switch v-model="isType" size="24" active-color="#ee0a24" />
			</template>
		</van-cell>

		<div style="padding:15px 15px;" class="createBtn">
			<van-button type="primary" block color="#ee0a24" round @click="submitAddAddress">
        {{$t('address.createAddress.confirm')}}
      </van-button>
		</div>
	</div>
</template>
<style scoped>
.createAddress .van-field{
	margin: 15px 0;
}
.createBtn{
	width: 100%;
	position: absolute;
	bottom: 20px;
	box-sizing: border-box;
}
</style>
<script>
import { getProCityAreaApi, addAddressApi } from '@/api/index';
import { Toast } from 'vant'
export default {
	props: {

	},
	data() {
		return {
			cascaderValue: '',
			streetList: [], //街道列表
			areaList: [],
			show: false,
			show_street: false,
			userName: '',//收货人姓名
			tel: '',//收货人电话
			pro: '',//省id
			prowz: '',//省名称
			city: '',//市id
			citywz: '',//市名称
			area: '',//区县id
			areawz: '',//区县名称
			stree: '',//街道id
			streewz: "",
			addressDetail: '',//详细地址
			provincesAndCity: '',//省市区全称
			isType: false,//是否设置为默认地址
			fieldNames: {
				text: 'name',
				value: 'kid',
				children: 'children'
			}
		}
	},
	created() {
		this.getProCityArea()
	},
	watch: {},
	methods: {
		async getProCityArea() { //初始化省市区数据
			let { data } = await getProCityAreaApi({ id: 0 })
			this.areaList = data
			// this.areaList.forEach(item => {
			// item.children = []
			// })
		},
		async onChange(e) { //选择地址项
			let { data } = await getProCityAreaApi({ id: e.value })
			if (data.length == 0) {
				// delete e.selectedOptions[e.selectedOptions.length - 1].children
				this.show = false;
			} else {
				this.$set(e.selectedOptions[e.selectedOptions.length - 1], 'children', [])
				this.addTree(e.selectedOptions, data, e.value)
			}
		},
		addTree(selectedOptions, children, id) {
			selectedOptions.forEach(item => {
				if (item.kid === id) {
					children.forEach(it => {
						item.children.push(it)
					})
				}
			})
		},
		onFinish(e) {
			// this.show = false;
			// console.log(e);

			if (e.selectedOptions.length == 4 || e.selectedOptions.length == 3) {
				console.log(e.selectedOptions.length == 4 || e.selectedOptions.length == 3);
				setTimeout(() => {
					if (this.show == false) {
						console.log('this.show == false', this.show == false);
						this.prowz = e.selectedOptions[0].name
						this.pro = e.selectedOptions[0].kid

						this.citywz = e.selectedOptions[1].name
						this.city = e.selectedOptions[1].kid

						this.areawz = e.selectedOptions[2].name
						this.area = e.selectedOptions[2].kid

						if (e.selectedOptions.length == 4) {
							this.streewz = e.selectedOptions[3].name
							this.stree = e.selectedOptions[3].kid
							this.provincesAndCity = this.prowz + ' ' + this.citywz + ' ' + this.areawz + ' ' + this.streewz
						} else if (e.selectedOptions.length == 3) {
							this.streewz = ''
							this.stree = ''
							this.provincesAndCity = this.prowz + ' ' + this.citywz + ' ' + this.areawz
						}
					}
				}, 500);
			}
		},
		//点击省市区输入框 弹出选择器
		clickinput() {
			this.show = true
		},
		//取消选择省市区
		cancelArea() {
			this.show = false
		},


		//显示街道选择器
		clickinput_street() {
			this.show_street = true
		},
		//点击确认 街道赋值
		confirmStreet(value) {
			console.log(value);
			this.streewz = value
			this.show_street = false
		},
		//取消选择街道
		cancelStreet() {
			this.show_street = false
		},

		//提交地址
		submitAddAddress() {
			let params = {
				name: this.userName,
				tel: this.tel,
				prowz: this.prowz,
				pro: this.pro,
				city: this.city,
				citywz: this.citywz,
				area: this.area,
				areawz: this.areawz,
				streewz: this.streewz,
				stree: this.stree,
				addressDetail: this.addressDetail,
				isType: this.isType == true ? 1 : 2
			}
			if(params.name == ''){
				Toast(this.$t('address.createAddress.input_receiver_name'))
				return false
			}
			if(params.tel == ''){
				Toast(this.$t('address.createAddress.input_receiver_contact_info'))
				return false
			}
			if(params.prowz == '' || params.pro == '' || params.city == '' || params.citywz == '' || params.area == '' || params.areawz == ''){
				Toast(this.$t('address.createAddress.choose_province_city_area'))
				return false
			}
			if(params.addressDetail == ''){
				Toast(this.$t('address.createAddress.input_address_detail'))
				return false
			}
			addAddressApi(params).then(res => {
				console.log(res);
				if (res.code == 200) {
					Toast.success(this.$t('address.createAddress.create_address_success'));
					this.$emit('submitAddAddress', res.data)
				}
			})

		}
	}
}
</script>